import { ChangeEvent, FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { orderApi } from "../../api/order.api";
import { useAccess } from "../../hooks/useAccess";
import { useTicket } from "../../hooks/useTicket";
import { useTicketMessage } from "../../hooks/useTicketMessage";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Page3Cols from "../../layouts/dashboard/Tpl3Cols";
import { IMessageType } from "../../model/IMessage";
import { IOrder, IOrderItem } from "../../model/IOrder";
import { ITicketType } from "../../model/ITicketType";
import { msg } from "../../msg";
import { getMsgState, getMsgStateErr, setMsgStateErrMap, setMsgStateMap } from "../../store/ticketSlice";
import Divider from "../common/Divider";
import Input from "../form/Input";
import TicketAction from "../ticketAction/TicketAction";
import UserSelect from "../user/UserSelect";
import TicketActionLog from "./TicketActionLog";
import TicketMainInfo from "./TicketMainInfo";
import TicketMessages from "./TicketMessages";
import TicketMsg from "./TicketMsg";
import TicketOrderDetails from "./TicketOrderDetails";
import TicketParamsForm, { ITicketParamsState } from "./TicketParamsForm";

export type TTicketState =  ITicketParamsState & {
  orderId?: IOrder['orderId'],
  orderItemId?: IOrderItem['id'],
  typeId: ITicketType['id'],
};

export interface ITicketNewMsg {
  type?: IMessageType,
  toUserId?: string,
  message: string,
  attachments: File[],
}

export type ITicketNewMsgMap = Record<string, ITicketNewMsg>;

export type ITicketNewMsgErrMap = Record<string, Partial<Record<keyof ITicketNewMsg, string>>>;

const TicketDetails: FC = () => {
  // --
  // -- Msg options for current user role
  // --
  const { getSettingAccess } = useAccess();
  const msgOption: { title: string, value: IMessageType }[] = [
    ...(getSettingAccess('ticket-public-msg') ? [{ title: 'Public Message',  value: 'Public' as IMessageType}] : []),
    ...(getSettingAccess('ticket-internal-msg') ? [{ title: 'Private Message',  value: 'Private' as IMessageType}] : []),
    ...(getSettingAccess('ticket-internal-note') ? [{ title: 'Internal Note', value: 'Note' as IMessageType }] : []),
  ]

  // --
  // -- Init states
  // --
  const initTicketDetails: TTicketState = {
    assignedUserId: '',
    assignedVendorId: '',
    followers: '',
    extraFollowers: '',
    priorityId: 1,
    statusId: 1,
    typeId: 1,
  };
 
  const initTicketNewMsg: ITicketNewMsg = {
    type: msgOption?.[0]?.value,
    toUserId: '',
    message: '',
    attachments: [],
  };

  const { currentTicketFromStore, fetchFollowers, editTicketParams } = useTicket();
  const { addMessage } = useTicketMessage();
  const [stateTicketErr, setStateTicketErr] = useState<Partial<Record<keyof TTicketState, string>>>();
  const [stateTicket, setStateTicket] = useState<TTicketState>(initTicketDetails);
  const [stateTicketOriginal, setStateTicketOriginal] = useState<TTicketState>(initTicketDetails);
  const [loading, setLoading] = useState<boolean>(false);
  
  const dispatch = useTypedDispatch();
  const msgState = useTypedSelector(getMsgState());
  const msgStateErr = useTypedSelector(getMsgStateErr());
  const setMsgState = (newMsgState: ITicketNewMsgMap) => dispatch(setMsgStateMap(newMsgState));
  const setMsgStateErr = (newMsgStateErr: ITicketNewMsgErrMap) => dispatch(setMsgStateErrMap(newMsgStateErr));
  
  useEffect(() => {    
    setMsgState({
      ...msgState,
      '': initTicketNewMsg
    });    
  }, []);


  // const [msgState, setMsgState] = useState<ITicketNewMsgMap>({
  //   '': initTicketNewMsg // init msg for new ticket
  // });
  // const [msgStateErr, setMsgStateErr] = useState<ITicketNewMsgErrMap>(); 
 

  // --
  // -- If new ticket tab open - create a message state for it
  // --
  useEffect(() => {    
    currentTicketFromStore?.id
    && !msgState?.[currentTicketFromStore.id]
    && setMsgState({
      ...msgState,
      [currentTicketFromStore.id]: {
        ...initTicketNewMsg,
        toUserId: (getSettingAccess('ticket-internal-scope-all') && '')
          || (getSettingAccess('ticket-internal-scope-vendor') && (currentTicketFromStore?.assignedVendorUser?.id || ''))
          || (getSettingAccess('ticket-internal-scope-agent') && (currentTicketFromStore?.assignedUser?.id || ''))
          || '',
      }      
    });    
  }, [currentTicketFromStore?.id]);

  // --
  // -- Set default recipient for the internal message
  // --
  // useEffect(() => {
  //   currentTicketFromStore?.id
  //   && msgState?.[currentTicketFromStore.id]
  //   && setMsgState((prevState) => ({
  //     ...prevState,
  //     [currentTicketFromStore.id]: {
  //       ...prevState?.[currentTicketFromStore.id],
  //       toUserId: (getSettingAccess('ticket-internal-scope-all') && '')
  //         || (getSettingAccess('ticket-internal-scope-vendor') && (currentTicketFromStore?.assignedVendorUser?.id || ''))
  //         || (getSettingAccess('ticket-internal-scope-agent') && (currentTicketFromStore?.assignedUser?.id || ''))
  //         || ''
  //     }
  //   }));    

  // }, [currentTicketFromStore?.id]);

  const { data: orders } = orderApi.useSearchOrderQuery(currentTicketFromStore?.orderId || '', {
    skip: !currentTicketFromStore?.orderId
  });  
  
  const handleMsgField =
    (id = '') =>
      (key: keyof Omit<ITicketNewMsg, 'attachments'>) =>
        (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => 
          msgState?.[id] && setMsgState({
            ...msgState,
            [id]: {
              ...msgState[id],
              [key]: e?.target?.value || '',
            }
          });

  const handleAddMsg = async () => {
    if (!currentTicketFromStore?.id || !msgState?.[currentTicketFromStore.id]) return;

    setLoading(() => true);

    // -- Delete message error state
    setMsgStateErr({
      ...msgStateErr,
      [currentTicketFromStore.id]: undefined,
    });

    const errs = await addMessage(currentTicketFromStore.id, msgState[currentTicketFromStore.id]);
    if (errs) 
      setMsgStateErr({
        ...msgStateErr,
        [currentTicketFromStore.id]: errs,
      });
    else {
      toast.success(msg('ticket-message-added'));

      setMsgState({
        ...msgState,
        [currentTicketFromStore.id]: initTicketNewMsg,
      });

      setStateTicket((prevState) => ({
        ...prevState,
        [currentTicketFromStore.id]: initTicketNewMsg,
      }));
    }
    setLoading(() => false);
  };

  // --
  // -- Update ticket params flow
  // --
  const handleUpdateTicket = async () => {
    if (!currentTicketFromStore?.id) return;
    setLoading(() => true);
    setStateTicketErr(() => undefined);

    const errs = await editTicketParams({
      idTicket: currentTicketFromStore.id,
      ...stateTicket,
    });

    if (errs) setStateTicketErr(() => errs);
    else toast.success(msg('success-ticket-editing'));

    setLoading(() => false);
  };
  
  // --
  // -- Fill ticket form
  // --
  useEffect(() => {
    if (currentTicketFromStore) {
      const [followers, extraFollowers] = fetchFollowers(currentTicketFromStore.followers);     

      const currentTicketState = {
        assignedUserId: currentTicketFromStore?.assignedUser?.id || '',
        assignedVendorId: currentTicketFromStore?.assignedVendorUser?.id || '',
        followers,
        extraFollowers,
        priorityId: currentTicketFromStore?.priority?.id || 1,
        statusId: currentTicketFromStore?.status?.id || 1,
        typeId: currentTicketFromStore?.type?.id || 1,
        orderId: currentTicketFromStore.orderId,
        orderItemId: currentTicketFromStore?.orderItemId || undefined,
      }

      setStateTicket(() => currentTicketState);
      setStateTicketOriginal(() => currentTicketState);
    }
  }, [currentTicketFromStore]);
  
  return !currentTicketFromStore
    ? null
    : (
      <Page3Cols
        leftSidebar={<TicketParamsForm
          state={stateTicket}
          stateOriginal={stateTicketOriginal}
          setState={setStateTicket} 
          stateErr={stateTicketErr}
          loading={loading}
          handleUpdateTicket={handleUpdateTicket}
        />}
        rightSidebar={
          <>
            <div className="p-4">
              <TicketOrderDetails
                orderItemId={Number(stateTicket.orderItemId)}
                {...(orders?.[0] || {})}
              />
            </div>
            <Divider />
            <div className="p-4">
              <TicketActionLog
                title="Action Log"
                logItems={currentTicketFromStore.actionLog}
              />
            </div>
          </>
        }
      >
        <div className="flex flex-col h-full">
          <div>
            <div className="p-4">
              <TicketMainInfo ticket={currentTicketFromStore} />              
            </div>
            <Divider />
          </div>

          <div className="flex-1 min-h-[300px] overflow-y-auto stylledScroll">
            <div>
              <TicketMessages ticket={currentTicketFromStore} />          
              <TicketAction
                ticket={currentTicketFromStore}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
          </div>

          <div>
            <Divider />
            <div className="p-4 flex flex-col gap-4">
              <div className="flex justify-between items-center">
                <div className="flex gap-4">
                  <div className="w-[150px]">
                    <Input
                      name="msgType"
                      value={msgState?.[currentTicketFromStore?.id]?.type || ''}
                      handle={handleMsgField(String(currentTicketFromStore?.id) || '')('type')}
                      disabled={loading}
                      error={msgStateErr?.[currentTicketFromStore?.id]?.type}
                      options={msgOption}
                    />
                  </div>
                  {msgState?.[currentTicketFromStore?.id]?.type === 'Private' && (
                    getSettingAccess('ticket-internal-scope-all')
                    || getSettingAccess('ticket-internal-scope-vendor')
                    || getSettingAccess('ticket-internal-scope-agent')
                  ) && (
                    <div className="flex gap-4">
                      <div className="mt-2">To</div>
                      <div className="w-[400px]">
                        <UserSelect
                          name="toUserId"
                          type="text"
                          value={msgState?.[currentTicketFromStore?.id]?.toUserId || ''}
                          handle={handleMsgField(String(currentTicketFromStore?.id) || '')('toUserId')}
                          disabled={loading}
                          error={msgStateErr?.[currentTicketFromStore?.id]?.toUserId}
                          isError={!!msgStateErr?.[currentTicketFromStore?.id]?.toUserId}
                          scope={
                            (getSettingAccess('ticket-internal-scope-vendor') && 'tickets_vendor')
                            || (getSettingAccess('ticket-internal-scope-agent') && 'tickets_agent')
                            || 'tickets'
                          }
                        />
                      </div>
                    </div>
                  )}                
                </div>
                <TicketAction ticket={currentTicketFromStore} mode="dropDown" />
              </div>
              <TicketMsg
                name="message"
                value={msgState?.[currentTicketFromStore?.id]?.message || ''}
                // handle={handleMsgField(String(currentTicketFromStore?.id) || '')('message')}
                setState={(value: string) => handleMsgField(String(currentTicketFromStore?.id) || '')('message')({ target: { value }} as ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>)}
                error={msgStateErr?.[currentTicketFromStore?.id]?.message}
                buttonTitle="Submit"
                handleClick={handleAddMsg}            
                disabled={loading}
                filesState={msgState?.[currentTicketFromStore?.id]?.attachments}
                setFileState={(files: File[]) => msgState?.[currentTicketFromStore?.id] && setMsgState({
                  ...msgState,
                  [currentTicketFromStore?.id]: {
                    ...msgState[currentTicketFromStore?.id],
                    attachments: files || [],
                  }
                })}
              />
            </div>
          </div>
        </div>
      </Page3Cols>
    );
}

export default TicketDetails;
