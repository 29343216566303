import { FC } from 'react';
import StringSkeloton from '../skeletons/StringSkeloton';
import formatNumber from '../../../utils/formatNumber';

const TotalElements: FC<{ 
  total?: number | string,
  loading?: boolean,
  className?: string,
}> = ({
  total,
  loading = false,
  className = '',
}) => {

  return total === undefined
    ? null
    : (
      <div className={`flex gap-2 items-center ${className}`}>
        <div className="">Total:</div>
        {loading
          ? <StringSkeloton wordLenMinPx={30} />
          : Number(total)
            ? formatNumber(total)
            : 'Not Found'
        }
      </div>
    );
}

export default TotalElements;
