import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { IPagesResponse } from "../api/IPagesResponse";
import { IApiPagable, IChangePagable, IPagable, IUnChangePagable } from "../api/IPagable";

export type IOnSort = (sort: IPagable["sort"], sortDir?: IPagable["sortDir"]) => void;

export interface IUsePagable<T> {
  initPagable: Partial<IPagable>,
  filterMap?: T, // If filter map changes - reset pagable params
  setFilterParams?: (filterMap: Partial<T>) => unknown,
}

const defaultPagable: IChangePagable = {
  page: 1,
  size: 50,
  sort: '',
  sortDir: '',
}

export default function usePagable <T>(pagableProps?: IUsePagable<T>) {
  const { ref, inView } = useInView({ threshold: 0 }); 

  // -- Computed pagable
  const [pagable, setPagable] = useState<IPagable & T | IPagable>();

  // -- Editable page parameters
  const [pagableState, setPagableState] = useState<IChangePagable & T | IChangePagable>(); 

  useEffect(() => {     
    setPagableState(() => ({      
      ...defaultPagable,     
      ...(pagableProps?.initPagable || {}),
      ...(pagableProps?.filterMap || {}),
      ...(pagableState?.sort
        ? { sort: pagableState?.sort }
        : {}
      ),      
      ...(pagableState?.sortDir
        ? { sortDir: pagableState?.sortDir }
        : {}
      ), 
    }))
  }, [pagableProps?.filterMap]);

  // --
  // -- Pagable parameters from API
  // --
  const [pagableApiState, setPagableApiState] = useState<IUnChangePagable>();
  const updatePagableApiState = (data?: IPagesResponse<any>) => 
    data && setPagableApiState(() => ({
      offset: data?.pageable?.offset,
      totalElements: data.totalElements,
      totalPages: data.totalPages,
    }));

  // -- To the Next page
  const nextPage = () => pagable
    && pagableApiState?.totalPages
    && pagable.page < pagableApiState.totalPages
    && setPagableState((prevPagable) => ({
      ...defaultPagable,
      ...(prevPagable || {}),
      page: (prevPagable?.page || 0) + 1,
    }));

  // -- To the First page
  const firstPage = () => 
    setPagableState((prevPagable) => ({
      ...defaultPagable,
      ...prevPagable,
      page: 0,
    }));

  // -- Go to the next page when scroll to the observer
  useEffect(() => { nextPage(); }, [inView]);

  const extractPagableFromApi = (source?: Partial<IPagable | IApiPagable<any>>): Partial<IPagable> => {
    const { content, ...pagable } = (source || {}) as IApiPagable<any>;
    return pagable || {};
  }    

  const updatePagable = (newPagable?: Partial<IPagable | IApiPagable<any>>) => {      
    const extractedPagable = extractPagableFromApi(newPagable);

    pagable && setPagableState((prevPagable) => ({
      ...defaultPagable,
      ...prevPagable,
      ...extractedPagable,
    }));
  }

  // -- Sorting
  const onSort: IOnSort = (sort, sortDir) => {
    pagableState && setPagableState(() => ({
      ...pagableState,
      page: 0,
      sort,
      sortDir: sortDir
        ? sortDir
        : pagableState?.sort === sort && pagableState?.sortDir === 'asc'
            ? 'desc'
            : 'asc',
    }));
  }

  // -- Connect result pagable to api pagable and pagable state
  useEffect(() => {
    pagableState && pagableApiState && setPagable((prevState) => ({
      ...(prevState || {}),
      ...pagableState,
      ...pagableApiState,
    }));
  }, [pagableState, pagableApiState]);

  // const updatePageParams = (newParams: Partial<IChangePagable>) => 

  return {
    loadTriggerComponent: <div className="h-2" ref={ref} />, 
    onSort,
    updatePagable,
    pagableApiState,
    updatePagableApiState,
    setPagable,
    pagableState,
    pagable,
    firstPage,
  }
}