import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IPageParams } from "../../api/IApi";
import { orderApi } from "../../api/order.api";
import useClickByKey from "../../hooks/useClickByKey";
import { useHandleField } from "../../hooks/useHandleField";
import useOrder, { initOrderSearchState } from "../../hooks/useOrder";
import { icons } from "../../icons/IconSet";
import { IOrder } from "../../model/IOrder";
import parseISOToString from "../../utils/parseISOToString";
import EmptyTable from "../common/empty/EmptyTable";
import Table, { ITableTh } from "../common/table/Table";
import Button from "../form/Button";
import Input from "../form/Input";
import TableSkeloton from "../common/skeletons/TableSkeloton";
import TotalElements from "../common/filter/TotalElements";

export interface TOrderSearchState {
  orderId: IOrder['orderId'],
  siEmail: IOrder['siEmail'],
  invoice: IOrder['invoice'],
};
export interface IOrderSliceInitState {
  searchState: TOrderSearchState,
}

const OrderList : FC = () => {
  const navigate = useNavigate();
  const { btnRef, onKeyUp } = useClickByKey();
  const { 
    getOrderSearchStateFromUrl,
    getStateAppliedUrlString,
    getOrderSearchPageParamFromUrl,
    getPageParamsAppliedUrlString,
  } = useOrder();
  
  // --
  // -- Search state
  // --

  const [orderSearchState, setOrderSearchState] = useState<TOrderSearchState>(getOrderSearchStateFromUrl());
  const handleSearchState = useHandleField(setOrderSearchState);

  // const [orderPageParams, setOrderPageParams] = useState<IPageParams>(getOrderSearchPageParamFromUrl());

  const isOrderStateEmpty = (): boolean => !Object.entries(orderSearchState).find(([_, value]) => !!value);
  const isUrlOrderStateEmpty = (): boolean => !!!Object.entries(getOrderSearchStateFromUrl()).find(([_, paramVal]) => !!paramVal);

  // --
  // -- Handlers
  // --
  const handelResetSearchState = () => setOrderSearchState(() => initOrderSearchState);
  
  const handelResetSearch = () => {
    handelResetSearchState();
    navigate('/orders');
  }

  // --
  // -- Get order from the API
  // --
  const { data: orders, isFetching } = orderApi.useSearchOrdersQuery(
    {
      ...getOrderSearchStateFromUrl(),
      ...getOrderSearchPageParamFromUrl(),
    },
    { 
      // -- If there is not any parameters for searching to skip searching
      skip: isUrlOrderStateEmpty(),
    },
  );

  const handleSearch = async () =>
    !isOrderStateEmpty() && applyParamsToSearch(getStateAppliedUrlString(orderSearchState));


  const applyParamsToSearch = (serachStr: string) => 
    navigate(`/orders?${serachStr}`);
    
  // -- Table header
  const thead: ITableTh[] = [
    { title: 'Order#', sortCol: 'orderId' },
    { title: 'Status', sortCol: 'status' },
    { title: 'Email', sortCol: 'siEmail' },
    { title: 'Invoice', sortCol: 'invoice' },
    { title: 'Street', sortCol: 'siStreet' },
    { title: 'City', sortCol: 'siCity' },
    { title: 'Name', sortCol: 'siName' },
    { title: 'Zip', sortCol: 'siZip' },
    { title: 'State', sortCol: 'siState' },
    { title: 'Phone', sortCol: 'siPhone' },
    { title: 'Carrier', sortCol: 'siCarrier' },
    { title: 'Create', sortCol: 'timeCreate' },
    { title: 'Shipped', sortCol: 'siDate' },
    { title: 'Update', sortCol: 'timeUpdate' },    
  ];
  
  return (
    <div className="flex flex-col gap-1 w-full h-full">      
      <div className="flex justify-between bg-bg_light items-end border-border_color border-b-[1px] p-4">
        <div className="flex gap-2 ">
          <div className="flex gap-2 items-end">
            <Button
              isNotFullWidth
              type="button"
              size="small"
              disabled={isFetching}
              className="py-[.7em] px-[.7em] text-text_pale"
              onClick={handelResetSearchState}
            >
              {icons('eraser', { width: '16px' })}
            </Button>
          </div>
          <Input
            label="Order #"
            name="orderId"
            placeholder='Order #'
            maxLength={12}
            classNameField="w-[7em]"
            value={orderSearchState.orderId}
            handle={handleSearchState('orderId')}
            className='col-span-6'
            disabled={isFetching}
            onKeyUp={onKeyUp}
          />
          <Input
            label="Invoice #"
            name="invoice"              
            placeholder='Invoice #'
            value={orderSearchState.invoice}
            handle={handleSearchState('invoice')}
            className='col-span-6'
            disabled={isFetching}
            onKeyUp={onKeyUp}
          />
          <Input
            label="Email"
            name="siEmail"              
            placeholder='Shipping Email'
            value={orderSearchState.siEmail}
            handle={handleSearchState('siEmail')}
            className='col-span-6'
            disabled={isFetching}
            onKeyUp={onKeyUp}
          />
          <div className="flex gap-2 items-end">
            <Button
              btnRef={btnRef}
              isNotFullWidth
              type="submit"
              size="small"
              disabled={isFetching || isOrderStateEmpty()}
              className="whitespace-nowrap flex gap-2 items-center"
              onClick={handleSearch}
            >
              Search
            </Button>
            {!!orders?.content?.length && (
              <Button
                isNotFullWidth
                type="button"
                size="small"
                disabled={isFetching}
                className="whitespace-nowrap flex gap-2 items-center"
                onClick={handelResetSearch}
              >
                Clear
              </Button>
            )}          
          </div>
        </div>
        <TotalElements
          className="px-4"
          total={orders?.totalElements}
          loading={isFetching}
        />
      </div>
      <div className="flex-1">        
        {isFetching && (
          <div className="flex w-full h-full justify-center items-start text-text">
            <TableSkeloton />
          </div>
        ) || !isUrlOrderStateEmpty() && orders?.content?.length && (
          <Table
            isPending={isFetching}
            pageParams={getOrderSearchPageParamFromUrl()}
            pagable={orders}
            updatePageParams={(newPageParams: Partial<IPageParams>) => applyParamsToSearch(getPageParamsAppliedUrlString({
                ...getOrderSearchPageParamFromUrl(),
                ...newPageParams,
              }
            ))}
            thead={thead} 
            tbody={orders.content.map(({ id, orderId, status, siEmail, invoice, timeCreate, timeUpdate, siStreet, siCity, siName, siZip, siState, siPhone, siDate, siCarrier}) => ({ 
                onClick: () => navigate(`${orderId}`),
                data: [
                  orderId,
                  status,
                  siEmail,
                  invoice,
                  siStreet,
                  siCity,
                  siName,
                  siZip,
                  siState,
                  siPhone,
                  siCarrier,
                  parseISOToString(timeCreate, false),
                  parseISOToString(siDate || '', false),
                  parseISOToString(timeUpdate, false),
                ]
              }))
            } 
          />
        ) || !isUrlOrderStateEmpty() && (
          <div className="flex w-full h-full py-4 justify-center items-center text-text">
            <EmptyTable />
          </div>
        ) || (
          <div className="flex w-full h-full justify-center items-center text-text">
            Please, choose parameters for searching
          </div>
        )
        }
      </div>
    </div>
  );
};

export default OrderList;