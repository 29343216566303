import { useEffect, useState } from "react";
import { filterMapLateOrderInitState, getLateFilterParams, setFilterParams } from "../store/lateOrdersSlice";
import { useTypedDispatch } from "./useTypedDispatch";
import { useTypedSelector } from "./useTypedSelector";

export default function useLateOrders() {
  const filterMap = useTypedSelector(getLateFilterParams());
  const dispatch = useTypedDispatch();

  const [filterApplied, setFilterApplied] = useState<boolean>();
  useEffect(() => {
    setFilterApplied(() => isFilterReseted());    
  }, [filterMap]);

  const setFilterCreatedDate = (from = '', till = '') => dispatch(setFilterParams({
    createdFrom: from,
    createdTill: till,
  }))

  const onFilterReset = () => dispatch(setFilterParams(filterMapLateOrderInitState));

  const isFilterReseted = (): boolean => !!Object.entries(filterMapLateOrderInitState)
    .find(([key, val]) => (filterMap as any)?.[key] !== val)


  return {
    filterMap,
    setFilterCreatedDate,
    filterApplied,
    onFilterReset,
  }
}