import { FC } from "react";
import { icons } from "../../icons/IconSet";
import DropMenu from "../common/dropmenu/DropMenu";
import DateRange from "../date/DateRange";
import Button from "../form/Button";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { TInputEvent } from "../form/Input";
import { getTiketsDateFilter, setTicketDates } from "../../store/ticketsFilterSlice";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const TicketsDateFilter : FC = () => {
  const dispatch = useTypedDispatch();
  const { createdFrom, createdTill } = useTypedSelector(getTiketsDateFilter()) || {};

  const handle = (e: TInputEvent) => {
    const [createdFrom, createdTill] = e.target.value.split(':');
    dispatch(setTicketDates({createdFrom, createdTill}));
  }; 

  return (
    <DropMenu
      width="580px"
      content={(
        <div className="p-2">
          <DateRange
            name="ticketDateFilter"
            handle={handle}            
            value={`${createdFrom || ''}:${createdTill || ''}`}
          />
        </div>
      )}
    >
      <Button
        isNotFullWidth
        type={createdFrom || createdTill ? 'submit' : 'button'}
        className="whitespace-nowrap flex gap-1 items-center font-normal pl-3 pr-3 pt-1 pb-1"
      >
        {
          createdFrom || createdTill
            ? `${createdFrom || '...'} - ${createdTill || '...'}`
            : 'No Defined Date'       
        }        
        {icons('chevron-down', { width: '14px' })}
      </Button>      
    </DropMenu>
  );
};

export default TicketsDateFilter;
