import { FC } from 'react';
import { IOrder } from '../../model/IOrder';
import parseISOToString from '../../utils/parseISOToString';
import InfoWidget from '../common/widgets/InfoWidget';
import FormTitle from '../form/FormTitle';
import PriceWidget from '../order/PriceWidget';
import arrayToNodeString from '../../utils/arrayToNodeString';
import ProductsList from './ProductsList';
import priceFormatter from '../../utils/priceFormatter';

const OrderDetails: FC<{ order: IOrder }> = ({ order }) => {
  
  return (
    <div className="">
      <div className="w-full grid grid-cols-12">
        <div className="xl:col-span-3 px-4 col-span-12 border-border_color border-b-[1px] pb-6">
          <FormTitle>Shipping Info</FormTitle>
          <div className="w-full grid grid-cols-12 gap-x-5 gap-y-8">
            <InfoWidget title="Tracking #" className="col-span-12">
              {order.siTrackingNum}
            </InfoWidget>

            <InfoWidget title="Name" className="col-span-12 sm:col-span-6" isTruncate>
              {order.siName}
            </InfoWidget>

            <InfoWidget title="Primary Phone" className="col-span-12 sm:col-span-6">
              {order.siPhone}
            </InfoWidget>

            <InfoWidget title="Company" className="col-span-12 sm:col-span-6">
              {order.siCompany}
            </InfoWidget>

            <InfoWidget title="Liftgate" className="col-span-12 sm:col-span-6">
              {order.liftgate}
            </InfoWidget>
            
            <InfoWidget title="Address" className="col-span-12 sm:col-span-12">
              {arrayToNodeString(', ', [order.siStreet, order.siCity, order.siState, order.siZip])} 
            </InfoWidget>
          </div>
        </div>

        <div className="xl:col-span-6 px-4 col-span-12 border-border_color border-b-[1px] lg:border-x-[1px] pb-6">
          <FormTitle>Main Info</FormTitle>
          <div className="w-full grid grid-cols-12 gap-x-8 gap-y-8">
            <InfoWidget title="Order #" className="col-span-12 sm:col-span-3">
              {order.orderId}
            </InfoWidget>

            <InfoWidget title="Email" className="col-span-12 sm:col-span-6" isTruncate>
              {order.siEmail}
            </InfoWidget>

            <InfoWidget title="Invoice #" className="col-span-12 sm:col-span-3">
              {order.invoice}
            </InfoWidget>

            <InfoWidget title="Status" className="col-span-12 sm:col-span-3">
              {order.status}
            </InfoWidget>

            <InfoWidget title="Created" className="col-span-12 sm:col-span-3">
              {parseISOToString(order.timeCreate, false)}
            </InfoWidget>

            <InfoWidget title="Updated" className="col-span-12 sm:col-span-3">
              {parseISOToString(order.timeUpdate, false)}
            </InfoWidget>

            <InfoWidget title="Web site" className="col-span-12 sm:col-span-3" isTruncate>
              {order.fromSite}
            </InfoWidget>

            <PriceWidget title="Price" className="col-span-12 sm:col-span-6" {...order} /> 

            <InfoWidget title="Comments" className="col-span-12 sm:col-span-6">
              {order.notes}
            </InfoWidget>         
          </div>
        </div>

        <div className="xl:col-span-3 px-4 col-span-12 border-border_color border-b-[1px] pb-6">
          <FormTitle>Billing Info</FormTitle>
          <div className="w-full grid grid-cols-12 gap-x-5 gap-y-8">

            <InfoWidget title="Email" className="col-span-12 sm:col-span-6" isTruncate>
              {order.piEmail}
            </InfoWidget>

            <InfoWidget title="Name" className="col-span-12 sm:col-span-6" isTruncate>
              {order.piName}
            </InfoWidget>

            <InfoWidget title="Primary Phone" className="col-span-12 sm:col-span-6">
              {order.piPhone}
            </InfoWidget>
            
            <InfoWidget title="Payment Address" className="col-span-12 sm:col-span-12">
              {arrayToNodeString(', ', [order.piStreet, order.piCity, order.piState, order.piZip])} 
            </InfoWidget>
          </div>
        </div>

        {!!order?.orderItems?.length && (
          <div className="px-4 col-span-12 border-border_color border-b-[1px] pb-6">
            <FormTitle>Products</FormTitle>
            <ProductsList products={order.orderItems} />
          </div>
        )}

      </div>
    </div>
  );
}

export default OrderDetails;
