import { FC, useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { icons } from "../../icons/IconSet";
import { getTiketsStatusesFilter, ITicketsFilterOption } from "../../store/ticketsFilterSlice";
import DropMenu from "../common/dropmenu/DropMenu";
import Button from "../form/Button";
import Checkbox from "../form/Checkbox";
import TextButtom from "../common/buttom/TextButtom";

const TicketStatusesFilter : FC<{
  onResetTicketStatus?: () => unknown,
  onClearTicketStatus?: () => unknown,
  onToggleStatus?: (id: ITicketsFilterOption['id']) => unknown,
  isFilterStatusReseted?: (currentStatusState?: ITicketsFilterOption[]) => boolean,
}> = ({
  onResetTicketStatus,
  onToggleStatus,
  onClearTicketStatus,
  isFilterStatusReseted,
}) => {
  const ticketStatusesState = useTypedSelector(getTiketsStatusesFilter());
    
  // --
  // -- Total Selected
  // --
  const [selectedAmount, setSelectedAmount] = useState<number>(); 
   
  useEffect(() => {
    setSelectedAmount(() => (ticketStatusesState || [])
      .reduce((acc, { isActive }) => acc + (isActive ? 1 : 0), 0))
  }, [ticketStatusesState]);

  return !ticketStatusesState || !ticketStatusesState?.length 
    ? null
    : (
      <DropMenu
        width="238px"
        links={[
          ...ticketStatusesState.map(({ id, name, isActive }) => ({
            name: (
              <div
                key={`col-${name}`}
                tabIndex={-1}
                role="button"
                onClick={() => onToggleStatus && onToggleStatus(Number(id))}
                className="flex justify-between items-center"
              >
                {name}
                <Checkbox
                  name={`col-${name}`}
                  value={isActive}
                  enablePropagation
                />
              </div>
            ),
          })),
          {
            name: (
              <div className="flex justify-end gap-4">
                <TextButtom
                  type="gray"
                  onClick={onClearTicketStatus}
                  disabled={ticketStatusesState?.findIndex(({ isActive }) => isActive) === -1}
                  underline
                >
                  Clear
                </TextButtom>

                <TextButtom
                  type="gray"
                  onClick={onResetTicketStatus}
                  disabled={isFilterStatusReseted && isFilterStatusReseted(ticketStatusesState || [])}
                  underline
                >
                  Reset
                </TextButtom>
              </div>        
            )
          },
        ]}
      >
        <Button
          isNotFullWidth
          type={isFilterStatusReseted && !isFilterStatusReseted(ticketStatusesState || []) ? 'submit' : 'button'}         
          className="whitespace-nowrap flex gap-1 items-center font-normal pl-3 pr-3 pt-1 pb-1"
        >
          Ticket Status
          {/* {ticketStatusesState?.length !== selectedAmount && ( */}
            <div>
              {selectedAmount}
              <span className="text-[80%]"> / </span>
              {ticketStatusesState.length}
            </div>              
          {/* )} */}
          {icons('chevron-down', { width: '14px' })}
        </Button>
      </DropMenu>
    );
}

export default TicketStatusesFilter;
