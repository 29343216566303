import { FC } from "react";
import { IUserScope, userApi } from "../../api/user.api";
import { useAccess } from "../../hooks/useAccess";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { icons } from "../../icons/IconSet";
import { IUser } from "../../model/IUser";
import { getTiketsAssignedUserFilter, ITicketUserFilter } from "../../store/ticketsFilterSlice";
import DropMenu from "../common/dropmenu/DropMenu";
import Truncate from "../common/Truncate";
import Button from "../form/Button";
import UserSelect from "../user/UserSelect";

interface IUserCondiions {
  scope: IUserScope,
  title: 'Agent' | 'User',
}

const TicketAssignedUserFilter : FC<{
  onResetAssignedUser?: () => unknown,
  onSetAssignedUser?: (idUser: ITicketUserFilter) => unknown,
}> = ({
  onResetAssignedUser,
  onSetAssignedUser,
}) => {
  const { getSettingAccess } = useAccess();
  const vendorAssignedState = useTypedSelector(getTiketsAssignedUserFilter());
  
  const { data: users } = userApi.useGetUsersQuery({ searchStr: '', scope: "tickets" });
  const getUserEmailById = (idSearch: IUser['id']): string | undefined => (users || [])
    .find(({ id }) => id === idSearch)
    ?.email;

  const conditions: IUserCondiions | null = 
    (getSettingAccess('ticket-asigned-all') && { title: 'User', scope: 'tickets' })
    || (getSettingAccess('ticket-asigned-agent') && { title: 'Agent', scope: 'tickets_agent' })
    || null;

  return !conditions
    ? null
    : <DropMenu
        width="238px"
        links={[
          { 
            name: (
              <UserSelect
                name="assignedUserId"
                placeholder={`Select Assigned ${conditions.title}`}
                type="text"
                value={vendorAssignedState || ''}
                handleChangeMyself={() => onSetAssignedUser && onSetAssignedUser('me')}
                handleChange={(user?: IUser) => onSetAssignedUser && onSetAssignedUser(!user
                  ? undefined
                  : user.id
                )}
                scope={conditions.scope}
              />
            )
          },
          {
            name: (
              <div className={`
                flex justify-end underline underline-offset-2
                ${vendorAssignedState ? '' : 'opacity-40'}
              `}>
                Reset
              </div>
            ),
            onClick: onResetAssignedUser,
          },
        ]}
  >
    <Button
      isNotFullWidth
      type={vendorAssignedState ? 'submit' : 'button'}         
      className="whitespace-nowrap flex gap-1 items-center font-normal pl-3 pr-3 pt-1 pb-1"
    >
      {vendorAssignedState
        ? <Truncate>
            Assigned to {
            vendorAssignedState === 'me'
              ? 'Me'
              : getUserEmailById(vendorAssignedState) || 'User'}
          </Truncate>
        : 'Assigned User'
      }      
      {icons('chevron-down', { width: '14px' })}
    </Button>
  </DropMenu>
    
}

export default TicketAssignedUserFilter;
