import { FC, useState } from "react";
import { useAccess } from "../../hooks/useAccess";
import useFilter from "../../hooks/useFilter";
import { icons } from "../../icons/IconSet";
import TextButtom from "../common/buttom/TextButtom";
import Divider from "../common/Divider";
import RotateIcon from "../common/RotateIcon";
import Button from "../form/Button";
import TicketAssignedUserFilter from "../ticketFilters/TicketAssignedUserFilter";
import TicketLastUpdatedFilter from "../ticketFilters/TicketLastUpdatedFilter";
import TicketPredefinedFilters from "../ticketFilters/TicketPredefinedFilters";
import TicketPriorityFilter from "../ticketFilters/TicketPriorityFilter";
import TicketsAgeFilter from "../ticketFilters/TicketsAgeFilter";
import TicketStatusesFilter from "../ticketFilters/TicketStatusesFilter";
import TicketTypesFilter from "../ticketFilters/TicketTypesFilter";
import TicketFilterGroup from "./TicketFilterGroup";
import TicketsDateFilter from "./TicketsDateFilter";
import TicketSearchField from "./TicketSearchField";
import TicketsTableColsFilter from "./TicketsTableColsFilter";

const TicketFilterPannel : FC = () => {
  const { getSettingAccess } = useAccess();
  const { 
    filterApplied,
    onFilterReset,
    applyFilterSet,
    appliedFilterSetTitle,
    onResetTicketTypes,
    onClearTicketTypes,
    onToggleType,
    onResetTicketStatus,
    onToggleStatus,
    isFilterStatusReseted,
    onClearTicketStatus,
    onResetTicketPriority,
    onTogglePriority,
    onClearTicketPriority,
    onResetAssignedUser,
    onSetAssignedUser,
    onSetTicketAge,
    onResetLastUpdated,
    onSetLastUpdated,
  } = useFilter();

  const [showFilters, setShowFilters] = useState<boolean>(false);
  // const allFilters = useTypedSelector(getAllFilters());
  // console.log('allFilters = ', allFilters);  

  return (
    <div className="flex flex-col gap-3 w-full">
      <div className="flex justify-between w-full">
        <div className="flex gap-3 items-end">
          <TicketFilterGroup>
            <Button
              isNotFullWidth       
              className="whitespace-nowrap flex gap-2 items-center font-normal pl-3 pr-3 pt-1 pb-1"
              type={filterApplied?.length ? 'submit' : 'button'}
              onClick={() => setShowFilters((prevState) => !prevState)}
            >
              {icons('filter', { width: '14px' })}
              Filters
              {!!filterApplied?.length && (
                <span className="font-semibold">
                  {filterApplied.length}
                </span>
              )}
              <RotateIcon isShown={showFilters}>
                {icons('chevron-down', { width: '16px' })}
              </RotateIcon>
            </Button>

            <TicketPredefinedFilters 
              applyFilterSet={applyFilterSet}
              appliedFilterSetTitle={appliedFilterSetTitle}
            />            
          </TicketFilterGroup>

          <Divider vertical height="h-[2em]" />

          <TicketFilterGroup>
            <TicketsTableColsFilter />
          </TicketFilterGroup>

          <Divider vertical height="h-[2em]" />

          <TicketFilterGroup>
            <TicketsDateFilter />
          </TicketFilterGroup>
        </div>

        <div className="flex">
          <TicketFilterGroup>
            <TicketSearchField />
          </TicketFilterGroup>
        </div>
      </div>

      <div className={`flex items-center gap-3 flex-wrap ${showFilters ? 'flex' : 'hidden'}`}>       
        <TicketTypesFilter
          onResetTicketTypes={onResetTicketTypes}
          onToggleType={onToggleType}
          onClearTicketTypes={onClearTicketTypes}
        />

        <TicketStatusesFilter
          onResetTicketStatus={onResetTicketStatus}
          onToggleStatus={onToggleStatus}
          onClearTicketStatus={onClearTicketStatus}
          isFilterStatusReseted={isFilterStatusReseted}
        />

        <TicketPriorityFilter 
          onResetTicketPriority={onResetTicketPriority}
          onTogglePriority={onTogglePriority}
          onClearTicketPriority={onClearTicketPriority}
        />

        {(getSettingAccess('ticket-asigned-all') || getSettingAccess('ticket-asigned-agent')) && (
          <TicketAssignedUserFilter
            onResetAssignedUser={onResetAssignedUser}
            onSetAssignedUser={onSetAssignedUser}
          />
        )}

        <TicketsAgeFilter
          onSetTicketAge={onSetTicketAge}
        />

        <TicketLastUpdatedFilter          
          onResetLastUpdated={onResetLastUpdated}
          onSetLastUpdated={onSetLastUpdated}
        />

        <TextButtom
          type="gray"
          onClick={onFilterReset}
          disabled={!!!filterApplied?.length}
          underline
        >
          Reset
        </TextButtom>

      </div>      
    </div>
  );
};

export default TicketFilterPannel; 
