import { FC, useMemo } from "react";
import { TTicketFilterState } from "../../api/ticket.api";
import { useAccess } from "../../hooks/useAccess";
import { icons } from "../../icons/IconSet";
import convertDateStrFromDate from "../../utils/convertDateStrFromDate";
import DropMenu from "../common/dropmenu/DropMenu";
import Button from "../form/Button";

const TicketPredefinedFilters : FC<{ 
  applyFilterSet?: (set: Partial<TTicketFilterState>, title?: string) => unknown,
  appliedFilterSetTitle?: string,
}> = ({
  applyFilterSet,
  appliedFilterSetTitle,
}) => {
  const { getSettingAccess } = useAccess();

  const filterSets = useMemo(() => ({
      ...(getSettingAccess('ticket-asigned-agent') || getSettingAccess('ticket-asigned-all')
        ? {
          'Assigned To Me': {
              assignedUser: 'me',
            }
          }
        : {}
      ),      
      'Urgent & High Tickets': {
        ticketPriorities: '3,4',
      },
      'Warranty Claim & Return': {
        ticketTypes: '1,2',
      },
      'Open/Pending > 2 Weeks': {
        ticketStatuses: '1,3',
        ageFrom: convertDateStrFromDate(new Date(+new Date() - 1000 * 60 * 60 * 24 * 14)),
      },
      'Customer Responded': {
        ticketStatuses: 'Customer',
      },
      'Archived': {
        ticketStatuses: '5',
      },
    } as Record<string, Partial<TTicketFilterState>>
  ), []);


  return (
    <DropMenu
      width="238px"
      links={[
        ...Object.entries(filterSets).map(([title, filterSet]) => ({
          name: title,
          onClick: () => {              
            applyFilterSet && applyFilterSet(filterSet, title);
          }
        })),          
      ]}
    >
      <Button
        isNotFullWidth
        type={appliedFilterSetTitle ? 'submit' : 'button'}         
        className="whitespace-nowrap flex gap-1 items-center font-normal pl-3 pr-3 pt-1 pb-1"
      >
        {appliedFilterSetTitle || 'Predefined Filters'}
        {icons('chevron-down', { width: '14px' })}
      </Button>
    </DropMenu>
  );
}

export default TicketPredefinedFilters;
