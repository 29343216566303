import randomIntFromInterval from "../../../utils/randomIntFromInterval";

export default function StringSkeloton({ 
  wordAmountMin = 1,
  wordAmountMax = wordAmountMin,
  wordLenMinPx = 50,
  wordLenMaxPx = wordLenMinPx,
} : {
  wordAmountMin?: number,
  wordAmountMax?: number,
  wordLenMinPx?: number,
  wordLenMaxPx?: number,
}) {

  return (
    <div className="animate-pulse flex gap-2">
      {[...Array(randomIntFromInterval(wordAmountMin, wordAmountMax))].map(() => (
        <div
          style={{ width: `${randomIntFromInterval(wordLenMinPx, wordLenMaxPx)}px` }}
          className={`bg-gray-300 h-3 rounded-md`}> 
        </div>
      ))}
    </div>
  );
}