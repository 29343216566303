import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import convertDateStrFromDate from '../utils/convertDateStrFromDate';

export interface IUnshippedOrderFilters {
  createdFrom: string,
  createdTill: string,
}

export interface IUnshippedOrders {
  filterParams: IUnshippedOrderFilters,
}

export const filterMapUnshippedInitState: IUnshippedOrderFilters = {
  createdFrom: convertDateStrFromDate(new Date(+new Date() - 1000 * 60 * 60 * 24 * 30)),
  createdTill: convertDateStrFromDate(new Date()),
}

const unshippedOrdersSlice = createSlice({
  name: 'unshippedOrders',
  initialState: {
    filterParams: filterMapUnshippedInitState,
  } as IUnshippedOrders,

  reducers: {
    setFilterParams: (state: IUnshippedOrders, action: PayloadAction<Partial<IUnshippedOrderFilters>>) => {
      state.filterParams = {
        ...state.filterParams,
        ...action.payload
      }
    },
  },
});

// Reducers
export default unshippedOrdersSlice.reducer;
export const {
  setFilterParams,
} = unshippedOrdersSlice.actions;

// -- 
// -- Selectors
// -- 
export const getUnshippedFilterParams = () => (state: RootState) => state.unshippedOrders.filterParams;
