import { FC, useEffect } from 'react';
import { IPageParams } from '../api/IApi';
import { IChangePagable } from '../api/IPagable';
import { orderApi } from '../api/order.api';
import TextButtom from '../components/common/buttom/TextButtom';
import TitlePage from '../components/common/title/TitlePage';
import DateFilter from '../components/filters/DateFilter';
import OrderUnshippedTable from '../components/orders/OrderUnshippedTable';
import TicketFilterGroup from '../components/tickets/TicketFilterGroup';
import usePagable from '../hooks/usePagable';
import useUnshippedOrders from '../hooks/useUnshippedOrders';
import { WrapInLayout } from '../layouts';
import Page3Line from '../layouts/dashboard/Page3Line';
import { IUnshippedOrderFilters } from '../store/unshippedOrdersSlice';
import TotalElements from '../components/common/filter/TotalElements';

const UnshippedOrders: FC = () => {  
  const { filterMap, setFilterCreatedDate, filterApplied, onFilterReset } = useUnshippedOrders();
  const { pagableState, updatePagableApiState, onSort, pagable, updatePagable } = usePagable({
    initPagable: {
      page: 0,
      size: 30,
      sort: 'timeCreate',
      sortDir: 'desc',
    },
    filterMap
  }); 

  const { data: orders, isFetching } = orderApi.useGetUnshippedOrdersQuery(
    (pagableState || {}) as IChangePagable & IUnshippedOrderFilters,
    { skip: !pagableState }
  );

  // -- init pagable object
  useEffect(() => {   
    updatePagableApiState(orders);
  }, [orders]);  

  return (
    <WrapInLayout title="Unshipped Orders" layout="dashboard">
      <Page3Line
        header={(
          <div className="flex flex-col gap-3 w-full">
            <TitlePage>Unshipped Orders</TitlePage>
            <div className="flex justify-between">
              <TicketFilterGroup>
                <DateFilter
                  pagable={pagable}
                  from={filterMap.createdFrom}
                  till={filterMap.createdTill}
                  setDates={setFilterCreatedDate}
                />
                {filterApplied && (
                  <TextButtom
                    type="gray"
                    onClick={onFilterReset}
                    underline
                  >
                    Reset
                  </TextButtom>
                )}
                
              </TicketFilterGroup>
              <TotalElements 
                total={orders?.totalElements}
                loading={isFetching}
              />   
            </div>       
          </div>
        )}
      >
        <OrderUnshippedTable
          isFetching={isFetching}
          orders={orders}          
          pageParams={pagable as IPageParams}
          onSort={onSort}
          updatePageParams={updatePagable}
        />
      </Page3Line>
    </WrapInLayout>
  );
};

export default UnshippedOrders;
