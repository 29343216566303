import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import convertDateStrFromDate from '../utils/convertDateStrFromDate';

export interface ILateOrderFilters {
  createdFrom?: string,
  createdTill?: string,
}

export interface ILateOrders {
  filterParams: ILateOrderFilters,
}

export const filterMapLateOrderInitState: ILateOrderFilters = {
  createdFrom: convertDateStrFromDate(new Date(+new Date() - 1000 * 60 * 60 * 24 * 30)),
  createdTill: convertDateStrFromDate(new Date()),
}

const lateOrdersSlice = createSlice({
  name: 'lateOrdersSlice',
  initialState: {
    filterParams: filterMapLateOrderInitState,
  } as ILateOrders,

  reducers: {
    setFilterParams: (state: ILateOrders, action: PayloadAction<Partial<ILateOrderFilters>>) => {
      state.filterParams = {
        ...state.filterParams,
        ...action.payload
      }
    },
  },
});

// Reducers
export default lateOrdersSlice.reducer;
export const {
  setFilterParams,
} = lateOrdersSlice.actions;

// -- 
// -- Selectors
// -- 
export const getLateFilterParams = () => (state: RootState) => state.lateOrdersSlice.filterParams;
