import { FC, useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { icons } from "../../icons/IconSet";
import { getTiketsTypesFilter, ITicketsFilterOption } from "../../store/ticketsFilterSlice";
import TextButtom from "../common/buttom/TextButtom";
import DropMenu from "../common/dropmenu/DropMenu";
import Button from "../form/Button";
import Checkbox from "../form/Checkbox";

const TicketTypesFilter : FC<{
  onResetTicketTypes?: () => unknown,
  onToggleType?: (id: ITicketsFilterOption['id']) => unknown,
  onClearTicketTypes?: () => unknown,
}> = ({
  onResetTicketTypes,
  onToggleType,
  onClearTicketTypes,
}) => {
  const ticketTypesState = useTypedSelector(getTiketsTypesFilter());
  const [selectedAmount, setSelectedAmount] = useState<number>();
  
  useEffect(() => {
    setSelectedAmount(() => (ticketTypesState || [])
      .reduce((acc, { isActive }) => acc + (isActive ? 1 : 0), 0))
  }, [ticketTypesState]);

  return !ticketTypesState || !ticketTypesState?.length 
    ? null
    : (
      <DropMenu
        width="238px"
        links={[
          ...ticketTypesState.map(({ id, name, isActive }) => ({
            name: (
              <div
                key={`col-${name}`}
                tabIndex={-1}
                role="button"
                onClick={() => onToggleType && onToggleType(Number(id))}
                className="flex justify-between items-center"
              >
                {name}
                <Checkbox
                  name={`col-${name}`}
                  value={isActive}
                  enablePropagation
                />
              </div>
            ),
          })),
          {
            name: (
              <div className="flex justify-end gap-4">
                <TextButtom
                  type="gray"
                  onClick={onClearTicketTypes}
                  disabled={ticketTypesState?.findIndex(({ isActive }) => isActive) === -1}
                  underline
                >
                  Clear
                </TextButtom>

                <TextButtom
                  type="gray"
                  onClick={onResetTicketTypes}
                  disabled={ticketTypesState?.length === selectedAmount}
                  underline
                >
                  Reset
                </TextButtom>
              </div>
            )
          },
        ]}
      >
        <Button
          isNotFullWidth
          type={ticketTypesState?.length !== selectedAmount ? 'submit' : 'button'}         
          className="whitespace-nowrap flex gap-2 items-center font-normal pl-3 pr-3 pt-1 pb-1"
        >
          Ticket Types
          {ticketTypesState?.length !== selectedAmount && (
            <div>
              {selectedAmount}
              <span className="text-[80%]"> / </span>
              {ticketTypesState.length}
            </div>              
          )}
          {icons('chevron-down', { width: '14px' })}
        </Button>
      </DropMenu>
    );
}

export default TicketTypesFilter;
