import { FC, PropsWithChildren } from 'react';

const TextButtom: FC<PropsWithChildren<{ 
  onClick?: () => unknown,
  type?: 'typical' | 'delete' | 'gray',
  disabled?: boolean,
  underline?: boolean,
}>> = ({
  onClick,
  type = 'typical',
  children,
  disabled = false,
  underline = false,
}) => (
  <div
    tabIndex={-1}
    role="button"
    onClick={() => onClick && !disabled && onClick()}
    className={`
      cursor-pointer flex
      ${disabled ? 'opacity-60' : ''}
      ${underline ? 'underline underline-offset-2' : ''}
      ${{
        typical: "text-primary hover:text-primary_hover",
        delete: "text-red-400 hover:text-red-500",
        gray: "text-gray-600 hover:text-gray-700",
      }[type]}
    `}
  >
    {children}
  </div>
);
export default TextButtom;
