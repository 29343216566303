import { ChangeEvent, FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAccess } from "../../hooks/useAccess";
import { useConfirm } from "../../hooks/useConfirm";
import { useHandleField } from "../../hooks/useHandleField";
import { useTicketMessage } from "../../hooks/useTicketMessage";
import { icons } from "../../icons/IconSet";
import { IMessage } from "../../model/IMessage";
import { ITicket } from "../../model/ITicket";
import { msg } from "../../msg";
import parseISOToString from "../../utils/parseISOToString";
import Divider from "../common/Divider";
import DropMenu from "../common/dropmenu/DropMenu";
import Modal from "../common/Modal";
import LoadedFileList from "../files/LoadedFileList";
import TicketMessageIcon from "./TicketMessageIcon";
import TicketMsg from "./TicketMsg";
import { replaceTagsFromText } from "../../utils/replaceTagsFromText";

export interface IEditedMessageState {
  message: string,
  attachments: File[],
}

const TicketMessage : FC<{ message: IMessage, ticket: ITicket }> = ({ message, ticket }) => {
  const { getTitleNode, deleteMessage, deleteMessageAttachment, getAttachmentById, editMessage } = useTicketMessage();
  const { getTicketMsgEditAccess, getTicketMsgDeleteAccess } = useAccess();
  const { messageType, messageText, created } = message;
  const confirm = useConfirm();

  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editedMsgStateErr, setEditedMsgStateErr] = useState<Partial<Record<keyof IEditedMessageState, string>>>();
  const [editedMsgState, setEditedMsgState] = useState<IEditedMessageState>({
    message: message.messageText,
    attachments: [],
  });
  const handle = useHandleField(setEditedMsgState);

  // -- Reset local attachments state after changes applying
  useEffect(() => {
    setEditedMsgState((prevState) => ({
      ...prevState,
      attachments: [],
    }));
  }, [message.attachments]);

  const handleDelete = () => confirm.on({
      text: (
        <div className="text-xl">
          Please confirm that you want to delete message:&nbsp;
          {getTitleNode(message, ticket) || 'unknown'}
        </div>
      ),
      action: async () => {
        setLoading(() => true);
        (await deleteMessage(message)) && toast.success(msg('ticket-msg-deleted'));
        setLoading(() => false);
      },
      applyText: 'Delete',
    });  

  const handleEdit = async () => {
    // setLoading(() => true);
    setEditedMsgStateErr(() => undefined);

    const errs = await editMessage(ticket, message.id, editedMsgState);
    if (errs) 
      setEditedMsgStateErr(() => errs);
    else {
      toast.success(msg('ticket-message-added'));
      setIsEdit(() => false);
    }
    setLoading(() => false);
  }

  const handleDeleteAttachment = (idAttachment: number) => confirm.on({
    text: (
      <div className="text-xl">
        Please confirm that you want to delete message attachment:&nbsp;
        <span className="font-bold">{getAttachmentById(message, idAttachment)?.name || 'unknown'}</span>
      </div>
    ),
    action: async () => {
      setLoading(() => true);
      (await deleteMessageAttachment(message, idAttachment, ticket)) && toast.success(msg('ticket-msg-attachment-deleted'));
      setLoading(() => false);
    },
    applyText: 'Delete',
  }); 

  return (
    <div className={`flex gap-4 ${loading ? 'animate-pulse opacity-50' : ''}`}>
      <TicketMessageIcon 
        msgType={messageType}
        isFromEmail={message?.isFromEmail}
      />
      <div className="flex-1">
        <div className=" flex justify-between">
          <div className="leading-5 flex flex-col gap-1">
            {getTitleNode(message, ticket)}
          </div>
          <div className="text-gray-500 flex gap-3 items-center">
            {!!(getTicketMsgDeleteAccess(message) || getTicketMsgEditAccess(message)) && (
              <DropMenu                
                links={[
                  ...(getTicketMsgEditAccess(message) 
                    ? [{ name: 'Edit Message', onClick: () => setIsEdit(() => true), isHideMenu: true }]
                    : []
                  ),
                  ...(getTicketMsgDeleteAccess(message)
                    ? [{ name: 'Delete Message', onClick: handleDelete, isHideMenu: true }]
                    : []
                  ),
                ]}
              >
                {icons('ellipsis-vertical', { width: '18px' })}
              </DropMenu>
            )}            
            {parseISOToString(created)}
          </div>
        </div>
        {!!message.messageMeta && <div className="mt-1">{message.messageMeta}</div>}
        {!!messageText && !!!message.actionType && (
          <div className="[&_a]:text-sky-700 [&_a:hover]:text-sky-500 [&_a]:cursor-pointer [&_a]:underline [&_a]:transition-all">
            <div dangerouslySetInnerHTML={{ __html: replaceTagsFromText(messageText) }} />
            {/* {message?.fromUser || (message.messageType === 'Public' && message.isFromEmail)
              ? <div dangerouslySetInnerHTML={{ __html: replaceTagsFromText(messageText) }} />
              : messageText} */}
          </div>
        )}
        {!!message?.attachments?.length && (
          <div className="mt-2 flex flex-col gap-4">
            <Divider />
            <LoadedFileList files={message?.attachments} />
          </div>
        )}
      </div>

      {!!isEdit && (
        <Modal
          title={(
            <div className="text-lg flex font-bold">
              Edit Message&nbsp;{getTitleNode(message, ticket)}
            </div>
          )}
          style={{ maxWidth: '600px' }}
          onClose={() => setIsEdit(() => false)}
        >
          <TicketMsg
            name="message"
            value={editedMsgState.message}
            handle={handle('message')}
            setState={(value: string) => handle('message')({ target: { value }} as ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>)}  
            error={editedMsgStateErr?.message}
            buttonTitle="Edit Message"
            handleClick={handleEdit}            
            disabled={loading}
            filesState={editedMsgState.attachments}
            setFileState={(files: File[]) => setEditedMsgState((prevState) => ({
              ...prevState,
              attachments: files,
            }))}
          />
          {!!message?.attachments?.length && (
            <div className="flex flex-col mt-5 bg-pale rounded-md border-[1px] border-slate-200">
              <div className="p-4 border-b-[1px] border-slate-300 text-slate-600">
                Previously Uploaded Files
              </div>
              <div className="m-4 mt-6">
                <LoadedFileList
                  files={message.attachments}
                  handleDeleteAttachment={handleDeleteAttachment}
                  disabled={loading}
                />
              </div>
            </div>
          )}          
          
        </Modal>
      )}
    </div>
  );
}

export default TicketMessage;
