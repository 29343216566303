import { FC } from "react";
import { icons } from "../../icons/IconSet";
import DropMenu from "../common/dropmenu/DropMenu";
import DateRange from "../date/DateRange";
import Button from "../form/Button";
import { TInputEvent } from "../form/Input";
import { IPagable } from "../../api/IPagable";

const DateFilter : FC<{
  pagable?: IPagable,
  from?: string,
  till?: string,
  setDates?: (from: string, till: string) => unknown,
}> = ({
  pagable,
  from,
  till,
  setDates,
}) => (
    <DropMenu
      width="580px"
      content={(
        <div className="p-2">
          <DateRange
            name="ticketDateFilter"
            handle={(e: TInputEvent) => {
              const [from, till] = e.target.value.split(':');
              setDates && setDates(from, till);
            }}            
            value={`${from || ''}:${till || ''}`}
          />
        </div>
      )}
    >
      <Button
        isNotFullWidth
        type={from || till ? 'submit' : 'button'}
        className="whitespace-nowrap flex gap-1 items-center font-normal pl-3 pr-3 pt-1 pb-1"
      >
        {
          from || till
            ? `${from || '...'} - ${till || '...'}`
            : 'No Defined Date'       
        }        
        {icons('chevron-down', { width: '14px' })}
      </Button>      
    </DropMenu>
  );

export default DateFilter;
