import { FC } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { icons } from "../../icons/IconSet";
import { getTiketsFilterLastUpdated } from "../../store/ticketsFilterSlice";
import DropMenu from "../common/dropmenu/DropMenu";
import Button from "../form/Button";
import Input from "../form/Input";

const lastUpdatedList = ['Customer', 'Agent', 'Vendor'];

const TicketLastUpdatedFilter : FC<{
  onResetLastUpdated?: () => unknown,
  onSetLastUpdated?: (item: string | undefined) => unknown,
}> = ({
  onResetLastUpdated,
  onSetLastUpdated,
}) => {
  const lastUpdated = useTypedSelector(getTiketsFilterLastUpdated());

  return (
    <DropMenu
      width="238px"
      links={[
        { 
          name: (
            <Input
              name="LastUpdatedFilter"
              placeholder="Select User Role"
              value={lastUpdated || ''}
              options={lastUpdatedList.map((value) => ({ value, title: value }))}
              handle={(event) => onSetLastUpdated && onSetLastUpdated(event.target.value)}
            />
          )
        },
        {
          name: (
            <div className={`
              flex justify-end underline underline-offset-2
              ${lastUpdated ? '' : 'opacity-40'}
            `}>
              Reset
            </div>
          ),
          onClick: onResetLastUpdated,
        },
      ]}
    >
      <Button
        isNotFullWidth
        type={lastUpdated ? 'submit' : 'button'}         
        className="whitespace-nowrap flex gap-1 items-center font-normal pl-3 pr-3 pt-1 pb-1"
      >
        Last Updated By {lastUpdated || ''}      
        {icons('chevron-down', { width: '14px' })}
      </Button>
    </DropMenu>
  );    
}

export default TicketLastUpdatedFilter;
