
export const CONFIG = {
  quotes: {
    quotesPerPage: 15,
  },
  offer: {
    partsPerPage: 15,
  },
  user: {
    usersPerPage: 15,
  },
  discounts: [...Array(21)].map((el, i) => i / 100),  
};