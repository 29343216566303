import { ITicketFullAction } from '../components/ticketAction/TicketAction';
import { IMessage, IMessageType } from '../model/IMessage';
import { ITicket } from '../model/ITicket';
import { IPageParams } from './IApi';
import { IPagesResponse } from './IPagesResponse';
import api from './api';

export interface IFollowerFormData {
  userId?: string,
  externalEmail?: string,
  externalName?: string,
}
export interface INewTicketFormData {
  orderId?: string,
  userId?: string,
  typeId?: number,
  priorityId?: number,
  statusId?: number,
  orderItemId?: number,
  message?: string,
  followers?: IFollowerFormData[],
}
export interface IEditTicketFormData {
  idTicket?: string | number,
  assignedUserId?: string,
  typeId?: number,
  priorityId?: number,
  statusId?: number,
  internalNote?: string,
  followers?: IFollowerFormData[],
  extraFollowers?: string,
}
export interface INewMsgFormData {
  message: string,
  attachments: any,
  fromUserId: string,
  toUserId: string,
  messageType: Omit<IMessageType, 'System'>,
}

export interface TTicketFilterState {
  createdFrom?: string;
  createdTill?: string;
    ticketStatuses?: string;
  excludeStatuses?: string;
    ticketTypes?: string;
    ticketPriorities?: string;
    assignedAgent?: string;
    assignedVendor?: string;
    assignedUser?: string;
    ageFrom?: string;
    ageTill?: string;
    lastUpdated?: string;
  orderId?: string;
  ticketId?: string;
  supplierOrderId?: string;
  customerEmail?: string;
  customerName?: string;
  customerPhone?: string;
}

export const ticketApi = api.injectEndpoints({
  endpoints: (build) => ({
    addTicket: build.mutation<ITicket, FormData>({
      query: (body) => ({
        method: 'POST',
        url: '/tickets',
        body,
      }),
    }),
    
    editTicket: build.mutation<ITicket, IEditTicketFormData>({
      query: ({ idTicket, ...body }) => ({
        method: 'PATCH',
        url: `/tickets/${idTicket}`,
        body,
      }),
      invalidatesTags: ['Tickets', 'TicketsList']
    }),

    getTicket: build.query<ITicket, string | number>({
      query: (id) => ({
        url: `/tickets/${id}`,
      }),
    }),

    getTicketList: build.query<ITicket[], string[]>({
      query: (ids) => ({
        url: `/tickets/list/?ids=${ids.join(',')}`,
      }),
      providesTags: ['TicketsList']
    }),

    getTickets: build.query<IPagesResponse<ITicket[]>, IPageParams & TTicketFilterState>({
      query: ({ sort, sortDir, ...params }) => ({
        url: '/tickets',
        params: {
          ...params,
          ...(params?.customerEmail
            ? { customerEmail: params?.customerEmail.trim() }
            : {}
          ),
          sort: sort ? `${sort},${sortDir}` : 'created,desc',
        },
      }),
      providesTags: ['Tickets']
    }),

    addMessage: build.mutation<ITicket, { idTicket: string | number, body: FormData/*INewMsgFormData*/ }>({
      query: ({ idTicket, body }) => ({
        method: 'POST',
        url: `/ticketmessages/${idTicket}`,
        body,
      }),
    }),

    editMessage: build.mutation<IMessage, { idMessage: string | number, body: FormData/*INewMsgFormData*/ }>({
      query: ({ idMessage, body }) => ({
        method: 'PATCH',
        url: `/ticketmessages/${idMessage}`,
        body,
      }),
    }),

    deleteMessage: build.mutation<ITicket, string | number>({
      query: (idMessage) => ({
        method: 'DELETE',
        url: `/ticketmessages/${idMessage}`,
      }),
    }),

    deleteMessageAttachment: build.mutation<ITicket, { idMessage: string | number, idAttachment: string | number }>({
      query: ({ idMessage, idAttachment }) => ({
        method: 'DELETE',
        url: `/ticketmessages/${idMessage}/${idAttachment}`,
      }),
      // invalidatesTags: ['Tickets', 'TicketsList']
    }),

    performAction: build.mutation<ITicket, { idTicket: string | number, body: FormData }>({
      query: ({ idTicket, body }) => ({
        method: 'POST',
        url: `/tickets/${idTicket}/action`,
        body,
      }),
    }),
  }),
});