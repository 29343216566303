import { combineReducers, configureStore } from '@reduxjs/toolkit';
import api from '../api/api';
import orderSlice, { IOrderSliceInitState } from './[DEPRICATED]orderSlice';
import authSlice, { IauthInitState } from './authSlice';
import commonSlice, { ICommonInitState } from './commonSlice';
import ticketsFilterSlice, { IticketsFilterInitState } from './ticketsFilterSlice';
import ticketSlice, { IticketInitState } from './ticketSlice';
import unshippedOrders, { IUnshippedOrders } from './unshippedOrdersSlice';
import userSlice, { IUserSliceInitState } from './userSlice';
import lateOrdersSlice, { ILateOrders } from './lateOrdersSlice';

export interface IInitReduxState {
  authSlice?: IauthInitState;
  ticketSlice?: IticketInitState;
  commonSlice?: ICommonInitState;
  ticketsFilterSlice?: IticketsFilterInitState;
  userSlice?: IUserSliceInitState;
  orderSlice?: IOrderSliceInitState;
  unshippedOrders?: IUnshippedOrders;
  lateOrdersSlice?: ILateOrders;
}

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  authSlice,
  ticketSlice,
  commonSlice,
  ticketsFilterSlice,
  userSlice,
  orderSlice,
  unshippedOrders,
  lateOrdersSlice,
});

// -- Redux store with initial state (for testing)
export const createReduxStore = (preloadedState?: IInitReduxState) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
    preloadedState: preloadedState || {},
  });

export const store = createReduxStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
