import { FC, useEffect } from 'react';
import { IChangePagable } from '../api/IPagable';
import { orderApi } from '../api/order.api';
import TextButtom from '../components/common/buttom/TextButtom';
import TitlePage from '../components/common/title/TitlePage';
import DateFilter from '../components/filters/DateFilter';
import OrderListTable from '../components/orders/OrderListTable';
import TicketFilterGroup from '../components/tickets/TicketFilterGroup';
import useLateOrders from '../hooks/useLateOrders';
import usePagable from '../hooks/usePagable';
import { WrapInLayout } from '../layouts';
import Page3Line from '../layouts/dashboard/Page3Line';
import { IPageParams } from '../api/IApi';
import TotalElements from '../components/common/filter/TotalElements';

const LateOrdersReport: FC = () => {  
  const { filterMap, setFilterCreatedDate, filterApplied, onFilterReset } = useLateOrders();
  const { pagableState, pagable, updatePagableApiState, updatePagable } = usePagable({
    initPagable: {
      page: 0,
      size: 30,
    },
    filterMap
  }); 

  const { data: orders, isFetching } = orderApi.useGetLateOrdersQuery(
    (pagableState || {}) as IChangePagable,
    { skip: !pagableState }
  ); 

  // -- init pagable object
  useEffect(() => {   
    updatePagableApiState(orders);
  }, [orders]);

  return (
    <WrapInLayout title="Unshipped Orders" layout="dashboard">
      <Page3Line
        header={(
          <div className="flex flex-col gap-3 w-full">
            <TitlePage>Late Orders report</TitlePage>
            <div className="flex justify-between">
              <TicketFilterGroup>
                <DateFilter
                  pagable={pagable}
                  from={filterMap.createdFrom}
                  till={filterMap.createdTill}
                  setDates={setFilterCreatedDate}
                />
                {filterApplied && (
                  <TextButtom
                    type="gray"
                    onClick={onFilterReset}
                    underline
                  >
                    Reset
                  </TextButtom>
                )}
                
              </TicketFilterGroup>
              <TotalElements 
                total={orders?.totalElements}
                loading={isFetching}
              />   
            </div>           
          </div>
        )}
      >
        <OrderListTable 
          isFetching={isFetching}
          orders={orders}
          pageParams={pagable as IPageParams}
          updatePageParams={updatePagable}
        />
      </Page3Line>
    </WrapInLayout>
  );
};

export default LateOrdersReport;
