import { IOrder } from '../model/IOrder';
import { ILateOrderFilters } from '../store/lateOrdersSlice';
import { IUnshippedOrderFilters } from '../store/unshippedOrdersSlice';
import api from './api';
import { IPageParams } from './IApi';
import { IChangePagable } from './IPagable';
import { IPagesResponse } from './IPagesResponse';

export interface ISearchOrder {
  orderId?: string,
  siEmail?: string,
  invoice?: string,
  createdFrom?: string,
  createdTill?: string,
}

export const orderApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchOrder: build.query<IOrder[], string>({
      query: (orderId) => ({
          url: '/ordermanagement/order',
          params: { orderId }
        }),
    }),

    getOrder: build.query<IOrder, string>({
      query: (orderId) => ({
          url: `/ordermanagement/order/${orderId}`,
        }),
    }),

    searchOrders: build.query<IPagesResponse<IOrder[]>, IPageParams & ISearchOrder>({
      query: ({ sort, sortDir, ...params }) => ({
          url: '/ordermanagement/search',
          params: {
            ...params,
            sort: sort ? `${sort},${sortDir}` : 'timeCreate,desc',
          },
        }),
    }),

    getUnshippedOrders: build.query<IPagesResponse<IOrder[]>, IChangePagable & IUnshippedOrderFilters>({
      query: ({ sort, sortDir, ...params }) => ({
          url: '/ordermanagement/unshipped_orders_report',
          params: {
            ...params,
            sort: sort
              ? `${sort},${sortDir || 'asc'}`
              : undefined,
            ...(!params.createdFrom || !params.createdTill
              ? {
                createdFrom: undefined,
                createdTill: undefined,
              } : {}
            ),
          },
        }),
    }),

    getUnshippedOrdersMerge: build.query<IPagesResponse<IOrder[]>, IChangePagable & IUnshippedOrderFilters>({
        query: ({ sort, sortDir, ...params }) => ({
          url: '/ordermanagement/unshipped_orders_report',
          params: {
            ...params,
            sort: sort
              ? `${sort},${sortDir || 'asc'}`
              : undefined,
            ...(!params.createdFrom || !params.createdTill
              ? {
                createdFrom: undefined,
                createdTill: undefined,
              } : {}
            ),
          },
        }),
        serializeQueryArgs: ({ endpointName }) => {
          return endpointName
        },
        // -- Merge incoming data if the page is more then 1
        merge: (currentCache, newItems, { arg }) => {        
          if ((arg?.page || 0) > 1) 
            currentCache.content.push(...newItems.content)
          else 
            return newItems;          
        },
        // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
        providesTags: ['Orders'],
    }),

    getLateOrders: build.query<IPagesResponse<IOrder[]>, IChangePagable & ILateOrderFilters>({
        query: ({ sort, sortDir, ...params }) => ({
          url: '/ordermanagement/late_orders_report',
          params: {
            ...params,
            ...(!params.createdFrom || !params.createdTill
              ? {
                createdFrom: undefined,
                createdTill: undefined,
              } : {}
            ),
            sort: undefined       
          },
        }),
    }),

    getLateOrdersMerge: build.query<IPagesResponse<IOrder[]>, IChangePagable & ILateOrderFilters>({
        query: ({ sort, sortDir, ...params }) => ({
          url: '/ordermanagement/late_orders_report',
          params: {
            ...params,
            ...(!params.createdFrom || !params.createdTill
              ? {
                createdFrom: undefined,
                createdTill: undefined,
              } : {}
            ),
            sort: undefined, // sort ? `${sort},${sortDirection}` : 'created,desc',          
          },
        }),
        serializeQueryArgs: ({ endpointName }) => {
          return endpointName
        },
        // -- Merge incoming data if the page is more then 1
        merge: (currentCache, newItems, { arg }) => {        
          if ((arg?.page || 0) > 1) 
            currentCache.content.push(...newItems.content)
          else 
            return newItems;          
        },
        // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
        providesTags: ['Orders'],
    }),
  }),
});