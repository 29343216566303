import { useEffect, useState } from "react";
import { commonApi } from "../api/common.api";
import { getAllFilters, ITicketAgeFilterDate, IticketsFilterInitState, ITicketsFilterOption, ITicketUserFilter, setAssignedUser, setTicketAge, setTicketLastUpdated, setTicketPriority, setTicketStatuses, setTicketTypes, togglePriority, toggleStatus, toggleType } from "../store/ticketsFilterSlice";
import { useTypedDispatch } from "./useTypedDispatch";
import { useTypedSelector } from "./useTypedSelector";
import { TTicketFilterState } from "../api/ticket.api";
import { useAccess } from "./useAccess";

export type TFilterState = Pick<IticketsFilterInitState, 'types' | 'status' | 'priority' | 'assignedUser' | 'age' | 'lastUpdated'>;

export default function useFilter() {
  const dispatch = useTypedDispatch();
  const { getSettingAccess } = useAccess();
  // --
  // -- Filter initial state
  // --
  const [filterInitialState, setFilterInitialState] = useState<TFilterState>();

  // --
  // -- Get filters base data
  // --
  const { data: common } = commonApi.useGetCommonQuery({});
  const allFilters = useTypedSelector(getAllFilters());
  
  // --
  // -- Initions filter state values
  // --
  const isTicketsFilterOptionStatesEqual = (state1: ITicketsFilterOption[], state2: ITicketsFilterOption[]): boolean => {
    const state2Map = (state2 || []).reduce((acc, { id, isActive }) => ({
      ...acc,
      [id]: isActive,
    }), {} as Record<number, boolean>);
    return !!!state1.find(({ id, isActive }) => state2Map?.[id] === undefined || state2Map[id] !== isActive);
  }

  const getInitValueStatus = (): ITicketsFilterOption[] => 
    (common?.ticketStatuses || [])
      .map((status) => ({ 
        ...status,
        isActive: Number(status.id) !== 5 // -- swich archived filter off in initial state
      }));
  const isFilterStatusReseted = (currentStatus?: ITicketsFilterOption[]): boolean => !!currentStatus && isTicketsFilterOptionStatesEqual(getInitValueStatus(), currentStatus);
    

  // -- Calculate initial filter state
  useEffect(() => {    
    common && setFilterInitialState(() => ({
      types: (common.ticketTypes || []).map((type) => ({ ...type, isActive: true })),
      status: getInitValueStatus(),
      priority: (common.ticketPriorities || []).map((priority) => ({ ...priority, isActive: true })),
      assignedAgent: undefined,
      assignedVendor: undefined,
      assignedUser: undefined,
      date: undefined,
      // age: undefined,      
      lastUpdated: undefined,
    }))
  }, [common]);

  const initFilters = (isReset = false) => {
    if (!filterInitialState) return;

    filterInitialState?.types
    && (!allFilters.types || isReset)
    && dispatch(setTicketTypes(filterInitialState.types));

    filterInitialState?.status
    && (!allFilters.status || isReset)
    && dispatch(setTicketStatuses(filterInitialState.status));

    filterInitialState?.priority
    && (!allFilters.priority || isReset)
    && dispatch(setTicketPriority(filterInitialState.priority));

    (!allFilters.assignedUser || isReset)
    && dispatch(setAssignedUser(undefined));

    ((!allFilters.age?.ageFrom && !allFilters.age?.ageTill) || isReset)
    && dispatch(setTicketAge({ ageFrom: '', ageTill: '' }));

    (!allFilters.lastUpdated || isReset)
    && dispatch(setTicketLastUpdated(filterInitialState.lastUpdated));
  }

// 'types' | 'status' | 'priority' | 'assignedAgent' | 'assignedVendor' | 'age'

  // -- Set initial filter state
  useEffect(() => {
    initFilters();
  }, [filterInitialState]);


  // -- Applied filter amount
  const [filterApplied, setFilterApplied] = useState<(keyof TFilterState)[]>([]);
  // -- [appliedFields, totalFields]
  const commonFilterAppliedAmount = (filter: 'types' | 'status' | 'priority'): [number, number] => [
    (allFilters?.[filter] || []).reduce((acc, { isActive }) => 
      acc + (isActive ? 1 : 0)
    , 0),
    (allFilters?.[filter] || []).length,
  ];

  const addAppliedFilter = (key: keyof TFilterState) => 
    setFilterApplied((prevState) => Array.from(new Set([...prevState, key])));
  const removeAppliedFilter = (key: keyof TFilterState) =>  
    setFilterApplied((prevState) => prevState.filter((item) => item !== key));

  useEffect(() => {
    if (allFilters?.types) {
      const [appliedTypes, allTypes] = commonFilterAppliedAmount('types');
      
      appliedTypes !== allTypes
        ? addAppliedFilter('types')
        : removeAppliedFilter('types')
    }
  }, [allFilters?.types]);

  useEffect(() => {
    if (allFilters?.status) {
      !isFilterStatusReseted(allFilters.status)
        ? addAppliedFilter('status')
        : removeAppliedFilter('status')
    }
  }, [allFilters?.status]);

  useEffect(() => {
    if (allFilters?.priority) {
      const [appliedTypes, allTypes] = commonFilterAppliedAmount('priority');
      appliedTypes !== allTypes
        ? addAppliedFilter('priority')
        : removeAppliedFilter('priority')
    }
  }, [allFilters?.priority]);

  useEffect(() => {
    allFilters?.assignedUser
        ? addAppliedFilter('assignedUser')
        : removeAppliedFilter('assignedUser')
  }, [allFilters?.assignedUser]);

  useEffect(() => {
    allFilters?.age?.ageFrom || allFilters?.age?.ageTill 
        ? addAppliedFilter('age')
        : removeAppliedFilter('age')
  }, [allFilters?.age]);

  useEffect(() => {
    allFilters?.lastUpdated
        ? addAppliedFilter('lastUpdated')
        : removeAppliedFilter('lastUpdated')
  }, [allFilters?.lastUpdated]);

  // --
  // -- Predefined filters
  // --
  const [appliedFilterSetTitle, setAppliedFilterSetTitle] = useState<string>();

  const applyFilterSet = (filterSet: Partial<TTicketFilterState>, filterSetTitle?: string) => {
    if (!filterInitialState || !filterSet) return;

    // -- Types
    if (filterSet.ticketTypes && filterInitialState?.types) {
      const checkedPrioritiyIds = filterSet.ticketTypes.split(',');
      const updatedTypes = filterInitialState.types.map((typeOriginal) => ({
        ...typeOriginal,
        isActive: checkedPrioritiyIds.includes(String(typeOriginal.id))
      }));      
      dispatch(setTicketTypes(updatedTypes));
    } else 
      filterInitialState?.types && dispatch(setTicketTypes(filterInitialState.types));

    // -- Priorities
    if (filterSet.ticketPriorities && filterInitialState?.priority) {
      const checkedPrioritiyIds = filterSet.ticketPriorities.split(',');
      const updatedPriorities = filterInitialState.priority.map((priorityOriginal) => ({
        ...priorityOriginal,
        isActive: checkedPrioritiyIds.includes(String(priorityOriginal.id))
      }));      
      dispatch(setTicketPriority(updatedPriorities));
    } else 
      filterInitialState?.priority && dispatch(setTicketPriority(filterInitialState.priority));

    // -- Statuses
    if (filterSet.ticketStatuses && filterInitialState?.status) {
      const checkedPrioritiyIds = filterSet.ticketStatuses.split(',');
      const updatedStatus = filterInitialState.status.map((statusOriginal) => ({
        ...statusOriginal,
        isActive: checkedPrioritiyIds.includes(String(statusOriginal.id))
      }));      
      dispatch(setTicketStatuses(updatedStatus));
    } else 
      filterInitialState?.status && dispatch(setTicketStatuses(filterInitialState.status));
    
    // -- Assigned to User    
    filterSet.assignedUser && (getSettingAccess('ticket-asigned-all') || getSettingAccess('ticket-asigned-agent'))
      ? dispatch(setAssignedUser(filterSet.assignedUser))
      : dispatch(setAssignedUser(undefined));

    dispatch(setTicketAge({ ageFrom: filterSet.ageFrom || '', ageTill: filterSet.ageTill || '' }))
    dispatch(setTicketLastUpdated(filterSet.lastUpdated || undefined));

    filterSetTitle && setAppliedFilterSetTitle(() => filterSetTitle);
  }

  // --
  // -- Filter Fields Methods
  // --
  const onResetTicketTypes = () => {
    if (!allFilters?.types?.length) return;    
    dispatch(setTicketTypes(filterInitialState?.types));
    setAppliedFilterSetTitle(() => undefined);
  } 
  const onToggleType = (id: ITicketsFilterOption['id']) => {
    dispatch(toggleType(id));
    setAppliedFilterSetTitle(() => undefined);
  }
  const onClearTicketTypes = () => {    
    dispatch(setTicketTypes((filterInitialState?.types || []).map((item) => ({
      ...item,
      isActive: false,
    }))));
    setAppliedFilterSetTitle(() => undefined);
  }

  const onResetTicketStatus = () => { 
    if (isFilterStatusReseted(allFilters.status)) return;
    dispatch(setTicketStatuses(getInitValueStatus()));
    setAppliedFilterSetTitle(() => undefined);
  } 
  const onToggleStatus = (id: ITicketsFilterOption['id']) => {
    dispatch(toggleStatus(id));
    setAppliedFilterSetTitle(() => undefined);
  }
  const onClearTicketStatus = () => {
    dispatch(setTicketStatuses((filterInitialState?.status || []).map((item) => ({
      ...item,
      isActive: false,
    }))));
    setAppliedFilterSetTitle(() => undefined);
  }

  const onResetTicketPriority = () => { 
    if (!allFilters?.priority?.length) return;    
    dispatch(setTicketPriority(filterInitialState?.priority));
    setAppliedFilterSetTitle(() => undefined);
  }
  const onTogglePriority = (id: ITicketsFilterOption['id']) => {
    dispatch(togglePriority(id));
    setAppliedFilterSetTitle(() => undefined);
  }
  const onClearTicketPriority = () => {
    dispatch(setTicketPriority((filterInitialState?.priority || []).map((item) => ({
      ...item,
      isActive: false,
    }))));
    setAppliedFilterSetTitle(() => undefined);
  }

  const onResetAssignedUser = () => { 
    if (!allFilters?.assignedUser) return;    
    dispatch(setAssignedUser(undefined));
    setAppliedFilterSetTitle(() => undefined);
  } 
  const onSetAssignedUser = (idUser: ITicketUserFilter) => {
    dispatch(setAssignedUser(idUser));
    setAppliedFilterSetTitle(() => undefined);
  }

  const onSetTicketAge = (age: ITicketAgeFilterDate) => {    
    dispatch(setTicketAge(age));
    setAppliedFilterSetTitle(() => undefined);
  }

  const onResetLastUpdated = () => { 
    if (!allFilters?.lastUpdated) return;    
    dispatch(setTicketLastUpdated(undefined));
    setAppliedFilterSetTitle(() => undefined);
  } 
  const onSetLastUpdated = (item: string | undefined) => {
    dispatch(setTicketLastUpdated(item));
    setAppliedFilterSetTitle(() => undefined);
  }
  
  const onFilterReset = () => { 
    initFilters(true);
    setAppliedFilterSetTitle(() => undefined);
  }

  return {
    appliedFilterSetTitle,
    filterApplied,
    commonFilterAppliedAmount,
    initFilters,
    applyFilterSet,

    onResetTicketTypes,
    onClearTicketTypes,
    onToggleType,
    onResetTicketStatus,
    onToggleStatus,
    onClearTicketStatus,
    isFilterStatusReseted,
    onResetTicketPriority,
    onTogglePriority,
    onClearTicketPriority,
    onSetTicketAge,
    onResetLastUpdated,
    onSetLastUpdated,
    onFilterReset,
    onResetAssignedUser,
    onSetAssignedUser,
  }  
}