import { FC } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { icons } from "../../icons/IconSet";
import { getTiketsAgeFilter, ITicketAgeFilterDate } from "../../store/ticketsFilterSlice";
import DropMenu from "../common/dropmenu/DropMenu";
import DateRange from "../date/DateRange";
import Button from "../form/Button";
import { TInputEvent } from "../form/Input";

const TicketsAgeFilter: FC<{
  onSetTicketAge?: (age: ITicketAgeFilterDate) => unknown
}> = ({
  onSetTicketAge,
}) => {
  const { ageFrom, ageTill } = useTypedSelector(getTiketsAgeFilter()) || {};

  const handle = (e: TInputEvent) => {
    const [ageFrom, ageTill] = e.target.value.split(':');
    onSetTicketAge && onSetTicketAge({ ageFrom, ageTill });
  }; 

  return (
    <DropMenu
      width="580px"
      content={(
        <div className="p-2">
          <DateRange
            name="ticketsLastResponseFilter"
            handle={handle}
            value={`${ageFrom || ''}:${ageTill || ''}`}
          />
        </div>
      )}
    >
      <Button
        isNotFullWidth
        type={ageFrom || ageTill ? 'submit' : 'button'}
        className="whitespace-nowrap flex gap-1 items-center font-normal pl-3 pr-3 pt-1 pb-1"
      >
        {
          ageFrom || ageTill
            ? `Age: ${ageFrom || '...'} - ${ageTill || '...'}`
            : 'Ticket Age'       
        }        
        {icons('chevron-down', { width: '14px' })}
      </Button>      
    </DropMenu>
  );
};

export default TicketsAgeFilter;
