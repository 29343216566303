import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IPagesResponse } from '../../api/IPagesResponse';
import { IOrder } from '../../model/IOrder';
import parseISOToString from '../../utils/parseISOToString';
import Table, { ITableTh, TUpdatePageParams } from '../common/table/Table';
import TableSkeloton from '../common/skeletons/TableSkeloton';
import EmptyTable from '../common/empty/EmptyTable';
import { IOnSort } from '../../hooks/usePagable';
import { IPageParams } from '../../api/IApi';

const thead: ITableTh[] = [
  { title: 'Order#', sortCol: 'orderId' },
  { title: 'Status', sortCol: 'status' },
  { title: 'Email', sortCol: 'siEmail' },
  { title: 'Invoice', sortCol: 'invoice' },
  { title: 'Street', sortCol: 'siStreet' },
  { title: 'City', sortCol: 'siCity' },
  { title: 'Name', sortCol: 'siName' },
  { title: 'Zip', sortCol: 'siZip' },
  { title: 'State', sortCol: 'siState' },
  { title: 'Phone', sortCol: 'siPhone' },
  { title: 'Carrier', sortCol: 'siCarrier' },
  { title: 'Create', sortCol: 'timeCreate' },
  { title: 'Shipped', sortCol: 'siDate' },
  { title: 'Update', sortCol: 'timeUpdate' }, 
];

const OrderListTable: FC<{
  isFetching?: boolean,
  orders?: IPagesResponse<IOrder[]>,
  loadTriggerComponent?: JSX.Element,
  updatePageParams?: TUpdatePageParams,
  pageParams?: IPageParams,
  onSort?: IOnSort
}> = ({
  isFetching,
  orders,
  loadTriggerComponent,
  updatePageParams,
  onSort,
  pageParams,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-1 w-full h-full">     
        {isFetching && !orders?.content && (
          <div className="flex w-full h-full justify-center items-start text-text">
            <TableSkeloton />
          </div>
        ) || orders?.content && (
          <>
            <Table
              isPending={isFetching}
              pagable={orders}
              thead={thead}
              onSort={onSort}
              updatePageParams={updatePageParams}
              pageParams={pageParams}
              tbody={orders.content.map(({ id, orderId, status, siEmail, invoice, timeCreate, timeUpdate, siStreet, siCity, siName, siZip, siState, siPhone, siDate, siCarrier}) => ({ 
                onClick: () => navigate(`/orders/${orderId}`),
                  data: [
                    orderId,
                    status,
                    siEmail,
                    invoice,
                    siStreet,
                    siCity,
                    siName,
                    siZip,
                    siState,
                    siPhone,
                    siCarrier,
                    parseISOToString(timeCreate, false),
                    parseISOToString(siDate || '', false),
                    parseISOToString(timeUpdate, false),
                  ]
                }))
              } 
            />
            {loadTriggerComponent}
          </>
        ) || (
          <div className="flex w-full h-full py-4 justify-center items-center text-text">
            <EmptyTable />
          </div>        
        )}
    </div>
  );
}

export default OrderListTable;
