import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { z } from 'zod';
import api, { getErrorFromCatch, serializeFieldErrors } from '../api/api';
import { IFollowerFormData, ticketApi, TTicketFilterState } from '../api/ticket.api';
import { ITicketFullAction } from '../components/ticketAction/TicketAction';
import { TNewTicketState } from '../components/tickets/AddTicketForm';
import { ITicketParamsState } from '../components/tickets/TicketParamsForm';
import { IFollower } from '../model/IFollower';
import { ITicket } from '../model/ITicket';
import { ITicketTab } from '../model/ITicketTab';
import { msg } from '../msg';
import { getTicketStatuses } from '../store/commonSlice';
import { getAllFilters } from '../store/ticketsFilterSlice';
import { addNewTicketTab, addTicketTab, getActiveTicketTab, getTicketStore, removeActiveTabId, removeAllTicketTabs, removeTicketTabById, setActiveTabId, setTicket, setTickets, setTicketTabs } from '../store/ticketSlice';
import { checkEmails } from '../utils/checkEmails';
import checkFormStateByZod from '../utils/checkFormStateByZod';
import filterFieldsInObject from '../utils/filterEmptyFieldsInObject';
import useSchemaSearchParams from './useSerarchParams';
import { useTypedDispatch } from './useTypedDispatch';
import { useTypedSelector } from './useTypedSelector';

// --
// -- Determine the form schema
// --
const newTicketScheme = z.object({
  orderId: z
    .string({ required_error: msg('empty-order-id') })
    .min(3, msg('empty-order-id')),
  // message: z
  //   .string({ required_error: msg('empty-message') })
  //   .min(3, msg('empty-message')),

} as Record<keyof TNewTicketState, any>);

export function useTicket() {
  const dispatch = useTypedDispatch();
  const param = useSchemaSearchParams();

  // -- Get data from global state
  const { ticketTabs, activeTabId, tickets } = useTypedSelector(getTicketStore());
  const activeTicketTab = useTypedSelector(getActiveTicketTab());
  const statuses = useTypedSelector(getTicketStatuses());
  
  // -- API routes
  const [tryToAddTicket] = ticketApi.useAddTicketMutation();
  const [getTicket] = ticketApi.useLazyGetTicketQuery();
  const [getTicketList] = ticketApi.useLazyGetTicketListQuery();
  const [editTicket] = ticketApi.useEditTicketMutation();
  const [tryToSetTicket] = ticketApi.usePerformActionMutation();

  // -- Local states
  const [isActiveTabNew, setIsActiveTabNew] = useState<boolean>();
  const [isUrlSyncWithState, setIsUrlSyncWithState] = useState<boolean>(() => false);
  const [currentTicketFromStore, setCurrentTicketFromStore] = useState<ITicket>();

  // -- Filters
  const ticketFilterState = useTypedSelector(getAllFilters());

  // --
  // -- Current ticket
  // --
  useEffect(() => {
    setCurrentTicketFromStore(() => tickets?.[String(activeTabId)]);
  }, [tickets, activeTabId]);

  // --
  // -- Tabs methods
  // --
  const getIdForNewTab = (): string => `new${String(Math.random()).slice(2)}`;
  const isTabNew = (id?: string): boolean => !!id?.includes('new');
  const checkExistedNewTab = (): ITicketTab | undefined => (ticketTabs || []).find(({ id }) => isTabNew(id)); 
  const fetchTicketTabFromTicket = ({ id, type, status, client }: ITicket): ITicketTab => ({
    id: String(id),
    typeId: type.id,
    statusId: status.id,
    userName: client.name,
  });

  // -- Add ticket tab for new ticket form
  const handleNewTicketTab = () => {
    const existedNewTab = checkExistedNewTab();
    existedNewTab
      ? handleSetActiveTabId(existedNewTab.id)()
      : dispatch(addNewTicketTab(getIdForNewTab()));
  }

  // -- Add ticket tab for existed ticket according ITicket objet
  const addExistedTicketTab = (ticket: ITicket) => dispatch(addTicketTab({
      id: String(ticket.id),
      typeId: ticket.type.id,
      statusId: ticket.status.id,
      userName: ticket.client.name,
    }));

  const addTicketById = async (id: string | number) => {
    // -- Check if ticket exits in the Store
    const ticketFromStore = tickets?.[id];
    if (ticketFromStore) return addExistedTicketTab(ticketFromStore);

    // -- If there isn't the ticket in the store - loaded it
    try {
      const ticketFromAPI = await getTicket(id).unwrap();
      dispatch(setTicket(ticketFromAPI)); // - Add loaded ticket to the global store
      addExistedTicketTab(ticketFromAPI); // - Add new tab for loaded ticket
    } catch (err) {
      toast.error(`${msg('failed-to-get-tickets')} ${getErrorFromCatch(err)}`);
    }
  } 

  const handleSetActiveTabId = (id: string) => () => dispatch(setActiveTabId(id));
  
  const handleRemoveTicketTabById = (id: string) => () => ticketTabs?.length === 1
    ? removeAllTabs() // -- if the current tab is the last - we need delete all tabs and tabs params
    : dispatch(removeTicketTabById(id));

  const handleRemoveActiveTabId = () => dispatch(removeActiveTabId());
  
  const removeActiveTab = () => activeTabId && handleRemoveTicketTabById(activeTabId)();

  // -- Delete all tabs include params
  const removeAllTabs = () => {    
    param.delete('currentTicketTab').push();
    param.delete('ticketTabs').push();
    dispatch(removeAllTicketTabs());
  }

  // --
  // -- Tab URL param methods
  // --
  useEffect(() => {
    activeTabId && param.set('currentTicketTab', activeTabId).push();
    !activeTabId && isUrlSyncWithState && param.delete('currentTicketTab').push();
  }, [activeTabId]);

  useEffect(() => {
    ticketTabs?.length && param.set('ticketTabs', ticketTabs.map(({ id }) => id).join(',')).push();
    !ticketTabs?.length && isUrlSyncWithState && param.delete('ticketTabs').push();
  }, [ticketTabs]);

  useEffect(() => {
    setIsActiveTabNew(() => isTabNew(activeTabId));
  }, [activeTabId]);

  // --
  // -- API methods
  // --

  // - Load tickets which belongs the tab list
  const loadTabInfoFromApi = async (ids: string[]): Promise<Record<string, ITicket>> => {
    const aimIds = ids.filter((id) => !isTabNew(id));
    if (!aimIds?.length) return {};

    try {
      const tickets = await getTicketList(aimIds).unwrap();
      return (tickets || []).reduce((acc, ticket) => ({
        ...acc,
        [ticket.id]: ticket,
      }) ,{} as Record<string, ITicket>);
    } catch (err) {
      toast.error(`${msg('failed-to-get-tickets')} ${getErrorFromCatch(err)}`);
      return {}
    }   
  }

  // --
  // -- method: Sync up tabs acording URL params string
  // --
  const syncTabsAcordingUrl = () => {
    if (!ticketTabs?.length) {      
      const { currentTicketTab, ticketTabs: ticketTabsIds } = param.params();     
      const ticketsIds = (ticketTabsIds || '').split(',');

      ticketsIds?.length && loadTabInfoFromApi(ticketsIds)
        .then((loadedTickets) => {
          // - 1. Set ticket tabs from loaded tickets
          dispatch(setTicketTabs(ticketsIds.reduce((acc, id) => [
            ...acc,
            ...(loadedTickets?.[id]
                ? [ fetchTicketTabFromTicket(loadedTickets[id]) ]
                : isTabNew(id)
                  ? [{ id }]
                  : []
            )], [] as ITicketTab[])));

          // - 2. Set current tab if exists
          currentTicketTab && handleSetActiveTabId(currentTicketTab)();
          // - 3. Set loaded tickets to global store
          Object.keys(loadedTickets)?.length && dispatch(setTickets(loadedTickets));
          // - 4 
          setIsUrlSyncWithState(() => true);
        });
    } else { setIsUrlSyncWithState(() => true); }
  };

  const refreshAllTickets = () => {
    syncTabsAcordingUrl();
    dispatch(api.util.invalidateTags(['Tickets']));
  };

  // --
  // -- Sync up tabs acording URL params string
  // --
  useEffect(() => { syncTabsAcordingUrl(); }, []);

  // --
  // -- Followers methods
  // --
  const calculateFollowers = ({ followers, extraFollowers }: { followers: string, extraFollowers: string }): IFollowerFormData[] => {
    const followersArr = followers?.trim() ? followers.trim().split(',') : [];
    const extraFollowersArr = extraFollowers?.trim() ? extraFollowers.trim().split(',') : [];
    
    return [
      ...(followersArr).map((userId) => ({ userId })),
      ...(extraFollowersArr).map((externalEmail) => ({ externalEmail })),
    ]
};

  const fetchFollowers = (followers: IFollower[]): [followersIds: string, extraFollowersEmails: string] => [
    followers.map(({ user }) => user?.id || '').filter(Boolean).join(','),
    followers.map(({ externalEmailAddress }) => externalEmailAddress ?? '').filter(Boolean).join(','),
  ];

  // --
  // -- Refresh ticket
  // --
  const [getTicketApi] = ticketApi.useLazyGetTicketQuery();
  const refreshTicket = async (idTicket: number) => {
    try {
      const data = await getTicketApi(idTicket).unwrap();
      dispatch(setTicket(data));
      toast.success(msg('ticket-was-refreshed'));
    } catch (err) {
      toast.error(`${msg('failed-to-refresh-ticket')} ${getErrorFromCatch(err)}`);           
      return serializeFieldErrors(err) ?? { message: getErrorFromCatch(err) };
    }    
  }


  // --
  // -- return: null - OK, { error? } - Error
  // --
  const addTicket = async (newTicketState: TNewTicketState): Promise<null | Partial<Record<keyof TNewTicketState, string>>> => {
    const { followers, extraFollowers, ...newTicketFormState } = newTicketState;
    if (extraFollowers && !checkEmails(extraFollowers.split(',')))
      return { extraFollowers: 'There is an email address with wrong format!' };

    // -- Client validation form
    const clientErrs = checkFormStateByZod(newTicketScheme, newTicketFormState);
    if (clientErrs) return clientErrs;
    
    const allFollowers = calculateFollowers({ followers, extraFollowers });        

    // -- Pack data into formData
    const { attachments, ...request } = newTicketState;
    const ticketRequest = { ...filterFieldsInObject(request, ''), followers: allFollowers };
    const formData = new FormData();    
    formData.append("request", new Blob([JSON.stringify(ticketRequest)], { type: "application/json" }));
    (attachments?.length
      ? attachments.forEach((file) => formData.append('attachments[]', file))
      : formData.append('attachments[]', new Blob([''])));

    try {
      const data = await tryToAddTicket(formData).unwrap();
      dispatch(setTicket(data));
      addExistedTicketTab(data);
      return null;
    } catch (err) {
      toast.error(`${msg('failed-to-add-ticket')} ${getErrorFromCatch(err)}`);           
      return serializeFieldErrors(err) ?? { message: getErrorFromCatch(err) };
    }    
  };

  // --
  // -- return: null - OK, { error? } - Error
  // --
  const editTicketParams = async (
    data: Partial<ITicketParamsState> & {
      idTicket: string | number,
      internalNote?: string,
  }): Promise<null | Partial<Record<keyof ITicketParamsState, string>>> => {
    const { followers, extraFollowers, ...editTicketFormState } = data;
    if (extraFollowers && !checkEmails(extraFollowers.split(',')))
      return { extraFollowers: 'There is an email address with wrong format!' };

    const allFollowers = followers !== undefined || extraFollowers !== undefined
      ? calculateFollowers({
          followers: followers || '',
          extraFollowers: extraFollowers || '',
        })
      : undefined;        

    try {
      const data = await editTicket({
        ...editTicketFormState,
        followers: allFollowers !== undefined
          ? allFollowers || []
          : undefined
      }).unwrap();

      dispatch(setTicket(data)); 
      // addExistedTicketTab(data);
      return null;
    } catch (err) {
      toast.error(`${msg('failed-to-add-ticket')} ${getErrorFromCatch(err)}`);           
      return serializeFieldErrors(err) ?? { assignedUserId: getErrorFromCatch(err) };
    }    
  };

  // --
  // -- Get ticket from the global store or load it if there isn't the ticket
  // --
  const getTicketFromStore = (id: string | number): ITicket | undefined => tickets?.[String(id)];

  // --
  // -- Calculate Filters
  // --
  const getTicketsFilterOptions = (): TTicketFilterState => {
    // -- Types
    const ticketTypes = ticketFilterState.types?.length
      && ticketFilterState.types.reduce((acc, { id, isActive }) => [
        ...acc,
        ...(isActive ? [id] : []),
      ], [] as number[]).join(','); 

    // -- Priorities
    const ticketPriorities = ticketFilterState.priority?.length
      && ticketFilterState.priority.reduce((acc, { id, isActive }) => [
        ...acc,
        ...(isActive ? [id] : []),
      ], [] as number[]).join(','); 

    // -- Statuses
    const ticketStatuses = ticketFilterState.status?.length
      && ticketFilterState.status.reduce((acc, { id, isActive }) => [
        ...acc,
        ...(isActive ? [id] : []),
      ], [] as number[]).join(',');   



    // -- Search
    const ticketSearch = ticketFilterState.search?.type
      && { [ticketFilterState.search.type]: ticketFilterState.search?.search || '' };

    return {
      ...(ticketTypes ? { ticketTypes } : {}),      
      ...(ticketPriorities ? { ticketPriorities } : {}),
      ...(ticketStatuses ? { ticketStatuses } : {}),
      ...(ticketFilterState?.date ? ticketFilterState?.date : {}), // - Created      
      ...(ticketFilterState?.age ? ticketFilterState?.age : {}), // - Age
      ...(ticketFilterState?.lastUpdated ? { lastUpdated: ticketFilterState.lastUpdated } : {}),
      ...(ticketFilterState?.assignedUser ? { assignedUser: ticketFilterState.assignedUser } : {}),
      ...ticketSearch,
    };
  };  


  // --
  // -- return: null - OK, string - Error
  // --
  const performAction = async (idTicket: number, action: ITicketFullAction ): Promise<null | Partial<Record<keyof ITicketFullAction, string>>> => {
    if (!idTicket || !action) {
      toast.error(`${msg('failed-to-perform-action')}: There is not enough data`);
      return { text: `${msg('failed-to-perform-action')}: There is not enough data` };
    }        

    const { key, attachments, text, type } = action;

    // -- Check required data
    if (!text && ['text', 'text_and_files'].includes(type))
      return { text: `${msg('no-action-text')}` };
    if (!attachments?.length && type === 'text_and_files')
      return { text: `${msg('no-action-file')}` };

    // -- Pack data into formData
    const formData = new FormData();    
    formData.append("request", new Blob([JSON.stringify({
      key,
      text,
    })], { type: "application/json" }));

    attachments?.length
      ? attachments.forEach((file) => formData.append('attachments[]', file))
      : formData.append('attachments[]', new Blob([''])); 

    try {
      const data = await tryToSetTicket({ idTicket, body: formData }).unwrap();
      dispatch(setTicket(data));
      return null;
    } catch (err) {
      toast.error(`${msg('failed-to-perform-action')} ${getErrorFromCatch(err)}`);           
      return { text: `${msg('failed-to-perform-action')} ${getErrorFromCatch(err)}` };
    }    
  };

  return {
    ticketTabs,
    activeTicketTab,
    statuses,
    activeTabId,
    isActiveTabNew,
    checkExistedNewTab,
    handleNewTicketTab,
    handleSetActiveTabId,
    handleRemoveTicketTabById,
    handleRemoveActiveTabId,
    addExistedTicketTab,
    removeActiveTab,
    addTicketById,
    refreshTicket,
    syncTabsAcordingUrl,
    refreshAllTickets,

    addTicket,
    editTicketParams,
    getTicketFromStore,
    currentTicketFromStore,
    fetchFollowers,
    getTicketsFilterOptions,
    performAction,
  };
}
