import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { TTicketTableCols } from '../components/tickets/TicketsTable';

export type TTicketsTableColOption = Record<TTicketTableCols, boolean>;
export interface ITicketsFilterOption {
  id: number,
  name: string,
  isActive: boolean,
}

export interface ITicketFilterOption {
  id: string,
  name: string,
}

export interface IticketsFilterDate {
  createdFrom?: string,
  createdTill?: string,
}

export interface ITicketAgeFilterDate {
  ageFrom?: string,
  ageTill?: string,
}

export type ITicketUserFilter = string | undefined;

export const TICKETS_FILTER_SEARCH_TAGS = {
  orderId: 'Order #',
  ticketId: 'Ticket #',
  customerEmail: 'Customer Email',
  customerName: 'Customer Name',
  customerPhone: 'Customer Phone'
};
export interface IticketsFilterSearch {
  type: keyof typeof TICKETS_FILTER_SEARCH_TAGS,
  search: string, 
}

export type ITicketFilterLastUpdated = string;

export interface IticketsFilterInitState {
  fields?: TTicketsTableColOption,
  types?: ITicketsFilterOption[],
  status?: ITicketsFilterOption[],
  priority?: ITicketsFilterOption[],
  assignedUser?: ITicketUserFilter,
  date?: IticketsFilterDate,
  age?: ITicketAgeFilterDate,
  search: IticketsFilterSearch,
  lastUpdated?: ITicketFilterLastUpdated,
}

const ticketsFilterSlice = createSlice({
  name: 'ticketsFilterSlice',
  initialState: {
    fields: undefined,
    types: undefined,
    status: undefined,
    priority: undefined,
    assignedUser: undefined,
    date: undefined,
    age: undefined,
    lastUpdated: undefined,
    search: {
      type: 'orderId',
      search: '',
    },
  } as IticketsFilterInitState,

  reducers: {
    setAllFilters: (state: IticketsFilterInitState, action: PayloadAction<IticketsFilterInitState>) => {
      state = action.payload;
    },
    setFilters: (state: IticketsFilterInitState, action: PayloadAction<Partial<IticketsFilterInitState>>) => {     
      state = {
        ...state,
        ...action.payload
      }
    },    

    setFields: (state: IticketsFilterInitState, action: PayloadAction<TTicketsTableColOption>) => {
      state.fields = action.payload;
    },
    toggleField: (state: IticketsFilterInitState, action: PayloadAction<TTicketTableCols>) => {
      if (!state.fields) return;
      const tmpFields = { ...state.fields };
      tmpFields[action.payload] = !tmpFields[action.payload];
      state.fields = tmpFields;
    },

    // -- Types
    setTicketTypes: (state: IticketsFilterInitState, action: PayloadAction<ITicketsFilterOption[] | undefined>) => {
      state.types = action.payload;
    },
    toggleType: (state: IticketsFilterInitState, action: PayloadAction<number>) => {      
      const typeIndx = (state?.types || []).findIndex(({ id }) => id === action.payload);
      if (!state.types || typeIndx < 0) return;

      const tmpTypes = [ ...state.types ];
      tmpTypes[typeIndx] = {
        ...tmpTypes[typeIndx],
        isActive: !tmpTypes[typeIndx].isActive,
      }
      state.types = tmpTypes;
    },

    // -- Statuses
    setTicketStatuses: (state: IticketsFilterInitState, action: PayloadAction<ITicketsFilterOption[] | undefined>) => {
      state.status = action.payload;
    },
    toggleStatus: (state: IticketsFilterInitState, action: PayloadAction<number>) => {      
      const statusIndx = (state?.status || []).findIndex(({ id }) => id === action.payload);
      if (!state.status || statusIndx < 0) return;

      const tmpStatus = [ ...state.status ];
      tmpStatus[statusIndx] = {
        ...tmpStatus[statusIndx],
        isActive: !tmpStatus[statusIndx].isActive,
      }
      state.status = tmpStatus;
    },

    // -- Statuses
    setTicketPriority: (state: IticketsFilterInitState, action: PayloadAction<ITicketsFilterOption[] | undefined>) => {
      state.priority = action.payload;
    },
    togglePriority: (state: IticketsFilterInitState, action: PayloadAction<number>) => {      
      const priorityIndx = (state?.priority || []).findIndex(({ id }) => id === action.payload);
      if (!state.priority || priorityIndx < 0) return;

      const tmpStatus = [ ...state.priority ];
      tmpStatus[priorityIndx] = {
        ...tmpStatus[priorityIndx],
        isActive: !tmpStatus[priorityIndx].isActive,
      }
      state.priority = tmpStatus;
    },
    setAssignedUser: (state: IticketsFilterInitState, action: PayloadAction<ITicketUserFilter>) => {
      state.assignedUser = action.payload;
    },

    setTicketDates: (state: IticketsFilterInitState, action: PayloadAction<{ createdFrom?: string, createdTill?: string }>) => {
      state.date = action.payload;
    },
    setTicketAge: (state: IticketsFilterInitState, action: PayloadAction<ITicketAgeFilterDate>) => {      
      state.age = action.payload;
    },
    setTicketLastUpdated: (state: IticketsFilterInitState, action: PayloadAction<ITicketFilterLastUpdated | undefined>) => {
      state.lastUpdated = action.payload;
    },
    setTicketSearch: (state: IticketsFilterInitState, action: PayloadAction<IticketsFilterSearch>) => {
      state.search = action.payload;
    },
  },
});

// Reducers
export default ticketsFilterSlice.reducer;
export const {
  setAllFilters,
  setFilters,
  setFields,
  toggleField,
  setTicketTypes,
  toggleType,
  setTicketStatuses,
  toggleStatus,
  setTicketPriority,
  togglePriority,
  setTicketDates,
  setTicketSearch,
  setAssignedUser,
  setTicketAge,
  setTicketLastUpdated,
} = ticketsFilterSlice.actions;

// -- 
// -- Selectors
// -- 
export const getAllFilters = () => (state: RootState) => state.ticketsFilterSlice;
export const getTiketsTableFields = () => (state: RootState) => state.ticketsFilterSlice.fields;
export const getTiketsTypesFilter = () => (state: RootState) => state.ticketsFilterSlice.types;
export const getTiketsStatusesFilter = () => (state: RootState) => state.ticketsFilterSlice.status;
export const getTiketsPrioritiesFilter = () => (state: RootState) => state.ticketsFilterSlice.priority;
export const getTiketsAssignedUserFilter = () => (state: RootState) => state.ticketsFilterSlice.assignedUser;
export const getTiketsDateFilter = () => (state: RootState) => state.ticketsFilterSlice.date;
export const getTiketsAgeFilter = () => (state: RootState) => state.ticketsFilterSlice.age;
export const getTiketsFilterSearch = () => (state: RootState) => state.ticketsFilterSlice.search;
export const getTiketsFilterLastUpdated = () => (state: RootState) => state.ticketsFilterSlice.lastUpdated;